import { Injectable, OnDestroy } from '@angular/core'
import { LOCALSTORAGE_KEYS } from '@upkey-platform/core/core-data-access'
import {
  LIVE_STREAM_END_DATE,
  LIVE_STREAM_TIMEZONE
} from '@upkey-platform/self-branding-course/self-branding-course-shared'
import { DateTime } from 'luxon'
import { Observable, Subject } from 'rxjs'
import { LocalStorageService } from './local-storage.service'

@Injectable({
  providedIn: 'root'
})
export class SelfBrandingLocalStorageService implements OnDestroy {
  private storageSub = new Subject<string>()
  constructor(private localStorageService: LocalStorageService) {}

  watchStorage(): Observable<any> {
    return this.storageSub.asObservable()
  }

  addSbcDataToStorage(data: any, liveEnded = false) {
    const storageData = this.localStorageService.getItem(
      LOCALSTORAGE_KEYS.SELF_BRANDING_COURSE_STREAM_INFO,
      false
    )
    const dataObj = storageData ? { ...storageData, ...data } : { ...data }
    localStorage.setItem(LOCALSTORAGE_KEYS.SELF_BRANDING_COURSE_STREAM_INFO, JSON.stringify(dataObj))
    if (liveEnded) {
      this.storageSub.next('changed')
    }
  }

  getSbcStorageItem() {
    return this.localStorageService.getItem(LOCALSTORAGE_KEYS.SELF_BRANDING_COURSE_STREAM_INFO, false)
  }

  getLiveStreamState() {
    const date = DateTime.fromISO(LIVE_STREAM_END_DATE)
    const { year, month, day, hour, minute, second } = date
    const endDate = DateTime.local(year, month, day, hour, minute, second).setZone(
      LIVE_STREAM_TIMEZONE,
      {
        keepLocalTime: true
      }
    )
    const today = DateTime.fromObject({}, { zone: LIVE_STREAM_TIMEZONE })
    const timeDifference = endDate.diff(today).milliseconds
    return timeDifference > 0
  }

  ngOnDestroy() {
    this.storageSub.complete()
  }
}
