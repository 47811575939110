import { Component, Inject } from '@angular/core'
import { Router } from '@angular/router'
import { StartScreenSource } from '@upkey-platform/accounts/user-shared'
import { IS_PLATFORM_SERVER } from '@upkey-platform/core/core-data-access'
import { ApplicationRoutes } from '../../../../app-routes-names'
import { PublicPaths } from '../../../../features/public/public-route-names'

@Component({
  selector: 'u-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  jobsRoute = PublicPaths.JobsPagePath
  careerCreationCourseLandingRoute = PublicPaths.CCCPagePath
  termsRoute = PublicPaths.TermsOfUsePath
  privacyRoute = PublicPaths.PrivacyPolicyPath
  aboutRoute = PublicPaths.AboutPath
  selfBrandingCourseLandingRoute = PublicPaths.SelfBrandingCoursePath
  year: number = new Date().getFullYear()
  freeResumeReviewRoute = `/${ApplicationRoutes.FreeResume}`
  startScreenSource = StartScreenSource

  constructor(@Inject(IS_PLATFORM_SERVER) private isServer: boolean, private router: Router) {}

  route() {
    if (!this.isServer) {
      // TODO: add navigation triggering in a router subscription
      if (this.router.url === '/') {
        const doc = document.getElementById('career-tools')
        doc?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        })
      } else {
        this.router.navigateByUrl('/')
      }
    }
  }

  routeToHome() {
    if (!this.isServer) {
      // TODO: add navigation triggering in a router subscription
      if (this.router.url === '/') {
        window.scroll(0, 5)
      } else {
        this.router.navigateByUrl('/')
      }
    }
  }
}
