<footer class="w-full" id="footer">
  <div class="public-container font-sans m-auto">
    <div class="w-full py-8 md:py-10">
      <div
        class="px-4 xl:px-0 flex flex-col lg:flex-row justify-between items-start lg:items-center space-y-3 md:space-y-0"
      >
        <div class="flex flex-col py-1">
          <u-headline heading="h3">
            <span class="text-white"
              >Ready to <span class="text-blue-100">launch</span> your career?</span
            >
          </u-headline>
        </div>
        <div class="pt-2">
          <u-button
            variant="normal"
            size="medium"
            icon="arrow-forward"
            iconPosition="right"
            [fontSize]="'14px'"
            [iconWidth]="'12px'"
            uTriggerAuth
            [startScreen]="'sign-up'"
            [source]="startScreenSource.direct"
            >Get started</u-button
          >
        </div>
      </div>
      <div class="px-4">
        <p class="text-xl text-white pt-2 leading-base">It’s free, no credit card required</p>
      </div>
      <div class="flex flex-col md:flex-row py-4 md:items-center">
        <div class="w-full md:w-4/12 md:items-stretch px-4 xl:px-0">
          <div class="flex flex-col md:mx-auto">
            <div class="flex items-center">
              <a (click)="routeToHome()">
                <u-logo width="200" height="100" textFill="#fff"></u-logo>
              </a>
            </div>
            <div class="flex flex-col space-y-4">
              <div class="flex xl:justify-between lg:w-2/12 cursor-pointer space-x-2 social">
                <a href="https://twitter.com/Hello_Upkey" target="blank">
                  <svg-icon key="twitter" size="lg"></svg-icon>
                </a>
                <a href="https://www.instagram.com/hello.upkey/" target="blank">
                  <svg-icon key="instagram-alt" size="lg"></svg-icon>
                </a>
                <a href="https://www.facebook.com/hello.upkey/" target="blank">
                  <svg-icon key="facebook-f" size="lg"></svg-icon>
                </a>
                <a href="https://www.linkedin.com/company/upkey" target="blank">
                  <svg-icon key="linkedin" size="lg"></svg-icon>
                </a>
                <a href="https://www.tiktok.com/@hello.upkey" target="blank">
                  <svg-icon key="tictok" size="lg" class="icon"></svg-icon>
                </a>
              </div>
              <a
                class="my-3 max-w-min text-lg cursor-pointer hover:text-blue-600"
                href="mailto:hello@upkey.com"
                >hello@upkey.com</a
              >
              <div class="flex md:mt-4">
                <div class="hidden md:block">
                  <div class="text-base font-normal pt-4">
                    © Copyright {{ year }} Upkey Inc. All rights reserved.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="lg:w-8/12 flex flex-col md:flex-row">
          <div class="section-wrapper product-section">
            <h5 class="section-header">Products</h5>
            <ul class="list-unstyled py-2.5">
              <li>
                <svg-icon key="pen-nib"></svg-icon>
                <a (click)="route()">Career tools</a>
              </li>
              <li>
                <svg-icon key="file-alt"></svg-icon>
                <a [routerLink]="freeResumeReviewRoute">Resume review</a>
              </li>
              <li>
                <svg-icon key="play-square"></svg-icon>
                <a [routerLink]="jobsRoute"> Jobs & internships</a>
              </li>
            </ul>
          </div>
          <div class="section-wrapper resource-section">
            <h5 class="section-header">Resources</h5>
            <ul class="list-unstyled py-2.5">
              <li>
                <svg-icon key="trophy"></svg-icon>
                <a [routerLink]="selfBrandingCourseLandingRoute">Self-branding course</a>
              </li>
              <li>
                <svg-icon key="read-book"></svg-icon>
                <a [routerLink]="careerCreationCourseLandingRoute">Career creation course</a>
              </li>

              <li>
                <svg-icon key="chat"></svg-icon>
                <a href="https://discord.gg/c2mJa9xged" target="blank">Community</a>
              </li>
            </ul>
          </div>

          <div class="section-wrapper blog-section">
            <h5 class="section-header">Blog</h5>
            <ul class="list-unstyled py-2.5">
              <li>
                <a href="https://blog.upkey.com/category/job-search/" target="blank" class="blog-link"
                  >Job search</a
                >
              </li>

              <li>
                <a href="https://blog.upkey.com/category/career-sucess/" target="blank" class="blog-link"
                  >Career success</a
                >
              </li>
              <li>
                <a href="https://blog.upkey.com/" target="blank" class="blog-link">Go to blog</a>
              </li>
            </ul>
          </div>

          <div class="section-wrapper company-section">
            <h5 class="section-header">Company</h5>
            <ul class="list-unstyled py-2.5">
              <li>
                <svg-icon key="bolt"></svg-icon>
                <a [routerLink]="aboutRoute">About</a>
              </li>
              <li>
                <svg-icon key="shield-plus"></svg-icon>
                <a [routerLink]="privacyRoute">Privacy policy</a>
              </li>
              <li>
                <svg-icon key="file"></svg-icon>
                <a [routerLink]="termsRoute">Terms</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="w-full px-4 md:hidden">
          <div class="text-base font-normal font-sans">
            © Copyright {{ year }} Upkey Inc. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
