import { Directive, ElementRef, Input, OnInit, OnDestroy } from '@angular/core'
import {
  IMixpanelData,
  IPixelData,
  IGoogleAnalyticsData
} from '../services/track-service/track.interface'
import { TrackService } from '../services/track-service/track.service'

@Directive({
  selector: '[uTrack]'
})
export class TrackDirective implements OnInit, OnDestroy {
  @Input() trackOn: 'click' | 'dblclick' = 'click'
  @Input() trackMixPanel: IMixpanelData
  @Input() trackPixel: IPixelData
  @Input() trackGA: IGoogleAnalyticsData

  constructor(private element: ElementRef, private trackService: TrackService) {}

  ngOnInit(): void {
    this.element.nativeElement.addEventListener(this.trackOn, this.handleTrackEvent)
  }

  ngOnDestroy(): void {
    this.element.nativeElement.removeEventListener(this.trackOn, this.handleTrackEvent)
  }

  handleTrackEvent = (el: HTMLElement, event: any) => {
    if (this.trackMixPanel) {
      this.trackService.mixpanel(this.trackMixPanel)
    }

    if (this.trackPixel) {
      this.trackService.pixel(this.trackPixel)
    }

    if (this.trackGA) {
      this.trackService.googleAnalytics(this.trackGA)
    }
  }
}
