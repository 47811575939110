import { isEmpty } from 'lodash'
import { IUserProfile } from '@upkey-platform/profiles/profile-shared'
import { IUser } from '@upkey-platform/accounts/user-shared'


export class ProfileCompletenessHelper {

  /***
   * NOTE: The percentage of completeness the profile is also recorded in the database (`users.profile_completeness`).
   * So after changing the calculation algorithm, don't forget to write a migration and change the data in DB.
   * @param userProfile
   * @param currentUser
   */
  static getPercents(userProfile: IUserProfile, currentUser: IUser): number {
    if (isEmpty(userProfile)) {
      return 0
    }

    const { user, createdAt, updatedAt, ...rest } = userProfile
    const requiredFields = [
      'summary',
      'education',
      'projects',
      'work',
      'skills',
      'languages',
      'webLinks'
    ]
    const importantUserFields = ['lastName', 'firstName', 'address', 'email', 'phone']

    if (requiredFields.length) {
      let completedFields = 0

      requiredFields.forEach((field) => {
        const selectedField = (userProfile as any)[field]
        if (Array.isArray(selectedField)) {
          completedFields += selectedField.length ? 1 : 0
        } else {
          completedFields += !selectedField ? 0 : 1
        }
      })

      if (currentUser) {
        importantUserFields.forEach((field) => {
          const selectedField = (currentUser as any)[field]
          if (selectedField) {
            if (field === 'address' && !isEmpty(selectedField)) {
              const addressField = Object.keys(selectedField)
              const addressFieldLength = addressField.length
              addressField.forEach((key) => {
                completedFields += !selectedField[key] ? 0 : 1 / addressFieldLength
              })
            } else {
              completedFields += !selectedField ? 0 : 1
            }
          }
        })
      }

      const fieldLength = importantUserFields.length + requiredFields.length

      return Math.round((completedFields / fieldLength) * 100)
    }

    return 0
  }
}
