<u-navbar>
  <div class="hidden md:block">
    <ng-container [ngTemplateOutlet]="desktopView"> </ng-container>
  </div>
  <div class="block md:hidden">
    <ng-container [ngTemplateOutlet]="mobileView"> </ng-container>
  </div>
</u-navbar>

<ng-template #desktopView>
  <nav
    class="public-container relative m-auto pt-2 lg:pt-0 px-4 lg:px-0 flex flex-wrap items-center justify-between py-4 lg:py-0"
  >
    <div class="flex-1 flex justify-between items-center">
      <ng-container [ngTemplateOutlet]="logo"> </ng-container>
    </div>
    <ng-container
      [ngTemplateOutlet]="navList"
      [ngTemplateOutletContext]="{ color: 'ghost' }"
    ></ng-container>
  </nav>
</ng-template>

<ng-template #mobileView>
  <nav class="relative m-auto p-3 flex flex-wrap items-center justify-between">
    <div class="flex-1 flex justify-between items-center">
      <ng-container [ngTemplateOutlet]="logo" [ngTemplateOutletContext]="{ color: '#fff' }">
      </ng-container>
    </div>
    <u-button
      class="md:hidden"
      size=""
      variant=""
      extraBtnClass="text-white text-xl leading-none w-6 h-6 border border-solid border-transparent bg-transparent flex items-center justify-center"
      [isIconOnly]="true"
      [icon]="showMenu ? 'close' : 'menu'"
      [iconWidth]="showMenu ? '16px' : '24px'"
      [fontSize]="showMenu ? '16px' : '24px'"
      (click)="toggleNavbar()"
    >
    </u-button>

    <ng-container
      [ngTemplateOutlet]="navList"
      [ngTemplateOutletContext]="{ isMobile: true }"
    ></ng-container>
  </nav>
</ng-template>

<ng-template #logo let-color="color" let-mobile="mobile">
  <a (click)="routeToHome()" routerLink="/">
    <u-logo [textFill]="color || '#000'" [width]="mobile || 126.9" height="32"></u-logo>
  </a>
</ng-template>

<ng-template #navList let-color="color" let-isMobile="isMobile">
  <div
    [ngClass]="{ hidden: !showMenu, flex: showMenu }"
    class="md:flex md:items-center md:w-auto w-full"
    id="menu"
  >
    <div class="absolute top-full inset-x-0 w-full md:static md:inset-0">
      <ul
        class="md:flex items-center justify-between w-full space-y-4 pt-3 md:pt-0 md:space-y-0 bg-black-700 md:bg-transparent px-3 md:px-0 text-lg text-gray pb-3 md:pb-0"
      >
        <!-- product dropdown for large screens -->
        <li class="hidden md:block">
          <ng-container [ngTemplateOutlet]="productsDesktop"></ng-container>
        </li>
        <hr />
        <!-- end of product dropdown for large screens -->
        <!-- product dropdown for mobile -->
        <li class="flex flex-col space-y-2 md:hidden">
          <ng-container [ngTemplateOutlet]="productsMobile"> </ng-container>
        </li>

        <!-- dropdown for large screens -->
        <li class="hidden md:block">
          <div class="dropdown group inline-block relative">
            <a class="nav-link nav-menu group" (click)="closeMenu()">
              <span>Career resources</span>
              <svg-icon
                key="angle-down"
                class="group-hover:rotate-180 nm-arrow group-hover:mt-0.5"
                size="lg"
              ></svg-icon>
            </a>
            <div class="dropdown-menu">
              <div class="md:pt-3 lg:pt-0">
                <div class="dropdown-inner-wrapper">
                  <a [routerLink]="selfBrandingCourseLandingRoute">
                    <div class="mt-0.5">
                      <svg-icon key="trophy"></svg-icon>
                    </div>

                    <div class="dropdown-text-wrapper">
                      <p class="dropdown-text">
                        <span class="link-title"> Self-branding course</span>
                        <span class="arrow-icon">&rarr;</span>
                      </p>
                      <p class="sub-text">How to find your voice - Intro to self-branding</p>
                    </div>
                  </a>
                  <a [routerLink]="careerCreationCourseLandingRoute">
                    <div class="mt-0.5">
                      <svg-icon key="read-book"></svg-icon>
                    </div>

                    <div class="dropdown-text-wrapper">
                      <p class="dropdown-text">
                        <span class="link-title"> Career creation course</span>
                        <span class="arrow-icon">&rarr;</span>
                      </p>
                      <p class="sub-text">Free course on creating your career</p>
                    </div>
                  </a>

                  <a href="https://discord.gg/c2mJa9xged" target="blank">
                    <div class="mt-0.5">
                      <svg-icon key="chat"></svg-icon>
                    </div>

                    <div class="dropdown-text-wrapper mb-1">
                      <p class="dropdown-text">
                        <span class="link-title">Community</span>
                        <span class="arrow-icon">&rarr;</span>
                      </p>
                      <p class="sub-text">Join other innovators and world shakers</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </li>
        <!-- end of dropdown for large screens -->

        <!-- for mobile -->
        <li class="flex flex-col space-y-2 md:hidden">
          <a class="py-1 md:py-0 font-sans flex items-center px-3 md:px-1 lg:px-3 text-white">
            Career resources</a
          >

          <a [routerLink]="selfBrandingCourseLandingRoute" (click)="closeMenu()" class="mobile-dd">
            <svg-icon key="trophy"></svg-icon>
            <span class="link-title">Self-branding course</span>
          </a>
          <a [routerLink]="careerCreationCourseLandingRoute" (click)="closeMenu()" class="mobile-dd">
            <svg-icon key="read-book"></svg-icon>
            <span class="link-title"> Career creation course</span>
          </a>

          <a href="https://discord.gg/c2mJa9xged" target="blank" class="mobile-dd">
            <svg-icon key="chat"></svg-icon>
            <span class="link-title">Community</span>
          </a>
        </li>

        <hr />
        <!-- end of for mobile -->
        <li>
          <a class="nav-menu cursor-pointer nm-link" href="https://blog.upkey.com/" target="blank">
            Blog
          </a>
        </li>
        <ng-container *ngIf="currentUser$ | async as user; else notLoggedIn">
          <li>
            <u-button
              baseElement="link"
              [variant]="isMobile ? 'white-outline' : 'ghost'"
              size="small"
              [shape]="'pill'"
              [routerLink]="dashboardRoute"
            >
              Go to your dashboard
            </u-button>
          </li>
        </ng-container>
        <ng-template #notLoggedIn>
          <div class="flex space-x-2 md:space-x-0 md:mt-0.5">
            <li class="md:mx-2">
              <u-button
                baseElement="link"
                [variant]="isMobile ? 'white-outline' : 'ghost'"
                size="small"
                [shape]="'curved'"
                uTriggerAuth
                [startScreen]="'login'"
                [routerLink]="loginRoute"
              >
                <span class="text-xl font-medium py-0.5">Log in</span>
              </u-button>
            </li>
            <li class="md:mx-2">
              <u-button
                baseElement="link"
                [variant]="isMobile ? 'white-outline' : 'black'"
                size="small"
                [shape]="'curved'"
                uTriggerAuth
                [startScreen]="'sign-up'"
                [routerLink]="signupRoute"
              >
                <span class="text-xl font-medium py-0.5">Get started</span>
              </u-button>
            </li>
          </div>
        </ng-template>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #productsDesktop>
  <div class="dropdown group inline-block relative">
    <a class="nav-link nav-menu group" (click)="closeMenu()">
      <span>Products</span>
      <svg-icon
        key="angle-down"
        class="group-hover:rotate-180 nm-arrow group-hover:mt-0.5"
        size="lg"
      ></svg-icon>
    </a>
    <div class="dropdown-menu">
      <div class="md:pt-3 lg:pt-0">
        <div class="dropdown-inner-wrapper">
          <a (click)="goToCareer()">
            <div class="mt-0.5">
              <svg-icon key="pen-nib"></svg-icon>
            </div>

            <div class="dropdown-text-wrapper">
              <p class="dropdown-text">
                <span class="link-title">Career tools</span>
                <span class="arrow-icon">&rarr;</span>
              </p>
              <p class="sub-text">Build an amazing resume for free</p>
            </div>
          </a>
          <a [routerLink]="freeResumeReviewRoute">
            <div class="mt-0.5">
              <svg-icon key="file-alt"></svg-icon>
            </div>

            <div class="dropdown-text-wrapper">
              <p class="dropdown-text">
                <span class="link-title"> Resume review</span>
                <span class="arrow-icon">&rarr;</span>
              </p>
              <p class="sub-text">Get a a free custom resume review</p>
            </div>
          </a>

          <a [routerLink]="jobsRoute" (click)="closeMenu()">
            <div class="mt-0.5">
              <svg-icon key="pc"></svg-icon>
            </div>
            <div class="dropdown-text-wrapper">
              <p class="dropdown-text">
                <span class="link-title">Jobs & internships</span>
                <span class="arrow-icon">&rarr;</span>
              </p>
              <p class="sub-text">Discover your dream opportunity</p>
              <p></p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #productsMobile>
  <a class="py-1 md:py-0 font-sans flex items-center px-3 md:px-1 lg:px-3 text-white"> Products</a>

  <a (click)="closeMenu(); goToCareer()" class="mobile-dd">
    <svg-icon key="pen-nib"></svg-icon>
    <span class="link-title">Career tools</span>
  </a>
  <a [routerLink]="freeResumeReviewRoute" (click)="closeMenu()" class="mobile-dd">
    <svg-icon key="file-alt"></svg-icon>
    <span class="link-title"> Resume review</span>
  </a>

  <a [routerLink]="jobsRoute" (click)="closeMenu()" class="mobile-dd">
    <svg-icon key="pc"></svg-icon>
    <span class="link-title"> Jobs & internships</span>
  </a>
</ng-template>
