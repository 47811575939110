import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { IResume } from '@upkey-platform/resumes/resumes-shared'
import { Pagination } from 'nestjs-typeorm-paginate'
import { tap } from 'rxjs'
import { MyResumesService } from '../my-resumes.service'
import {
  DeleteResume,
  GetAllResumes,
  GetMyResumes,
  GetMyResumeScoreById,
  SaveNewResume,
  GetResumeDetails,
  SetResumeTemplate,
  SetResumeTemplateById,
  UploadResume,
  GetMyResumeSucksDetails,
  ShareResumeSucksByEmail,
  UploadResumeSucks,
  GetResumeByID
} from './my-resumes.actions'
import { MyResumesStateModel } from './my-resumes.model'
import { GetCurrentUser } from '@upkey-platform/accounts/user-data-access'
import { GetUserProfile } from '@upkey-platform/profiles/profile-data-access'
import { YourResumeSucksService } from '../../your-resume-sucks/your-resume-sucks.service'

@State<MyResumesStateModel>({
  name: 'resumes'
})
@Injectable()
export class MyResumesState {
  constructor(
    private myResumesService: MyResumesService,
    private store: Store,
    private yourResumeSucksService: YourResumeSucksService
  ) {}

  @Selector()
  static getResumes(state: MyResumesStateModel) {
    return state.resumes
  }

  @Selector()
  static getSelectedResume(state: MyResumesStateModel) {
    return state.resume
  }

  @Selector()
  static getSelectedTemplate(state: MyResumesStateModel) {
    return state.template
  }

  @Selector()
  static paginationMeta(state: MyResumesStateModel) {
    return state.paginationMeta
  }

  @Selector()
  static allResumes(state: MyResumesStateModel) {
    return state.resumes || []
  }

  @Selector()
  static lastUploadedResume(state: MyResumesStateModel) {
    if (state.resumes && state.resumes.length > 0) {
      return state.resumes[0]
    }
    return
  }

  @Selector()
  static getSuckResume(state: MyResumesStateModel) {
    return state.suckResume
  }

  @Action(GetMyResumes)
  getResumes(ctx: StateContext<MyResumesStateModel>, { payload }: GetMyResumes) {
    return this.myResumesService.getResumes(payload?.paginationInfo).pipe(
      tap((res: Pagination<IResume>) => {
        ctx.patchState({
          resumes: res.items,
          paginationMeta: res.meta
        })
      })
    )
  }

  @Action(GetResumeDetails)
  getResumeDetails(ctx: StateContext<MyResumesStateModel>, { payload }: GetResumeDetails) {
    return this.myResumesService.getResumeDetailsById(payload).pipe(
      tap((resume: IResume) => {
        ctx.patchState({ resume })
      })
    )
  }

  @Action(GetMyResumeSucksDetails)
  getMyResumeSucksDetails(ctx: StateContext<MyResumesStateModel>, { payload }: GetMyResumeSucksDetails) {
    return this.myResumesService.getMyResumeSucksDetailsById(payload).pipe(
      tap((suckResume: IResume) => {
        ctx.patchState({ suckResume })
      })
    )
  }

  @Action(GetMyResumeScoreById)
  getResumeScoreById(ctx: StateContext<MyResumesStateModel>, { payload }: GetMyResumeScoreById) {
    return this.myResumesService.getResumeScoreById(payload).pipe(
      tap((scored: IResume) => {
        const _resumes = ctx.getState()?.resumes || []
        const resumes = [..._resumes]
        const resumeIndex = resumes.findIndex((resume) => resume.id === scored.id)
        if (!!resumes.length && resumeIndex > -1) {
          resumes?.splice(resumeIndex, 1, scored)
        }
        ctx.patchState({
          resumes: [...resumes]
        })
      })
    )
  }

  @Action(SetResumeTemplateById)
  setTemplateById(ctx: StateContext<MyResumesStateModel>, { payload }: SetResumeTemplateById) {
    return this.myResumesService.generateResumeTemplate(payload).pipe(
      tap((template) => {
        ctx.patchState({
          template
        })
      })
    )
  }

  @Action(SetResumeTemplate)
  setTemplate(ctx: StateContext<MyResumesStateModel>, { template }: SetResumeTemplate) {
    ctx.patchState({
      template
    })
  }

  @Action(GetAllResumes)
  getAllResumes(ctx: StateContext<MyResumesStateModel>) {
    return this.myResumesService.getAllResumes().pipe(
      tap((res: IResume[]) => {
        ctx.patchState({
          resumes: res
        })
      })
    )
  }

  @Action(UploadResume)
  uploadResume(ctx: StateContext<MyResumesStateModel>, { payload }: UploadResume) {
    const allResumes = ctx.getState()?.resumes ?? []
    return this.myResumesService.uploadResume(payload).pipe(
      tap((res: IResume) => {
        ctx.patchState({
          resumes: [res, ...allResumes]
        })

        // On the first upload API will extract data from resume and populate user's profile with them
        if (allResumes.length === 0) {
          this.store.dispatch([new GetCurrentUser(), new GetUserProfile()])
        }
      })
    )
  }

  @Action(DeleteResume)
  deleteResume(ctx: StateContext<MyResumesStateModel>, { payload }: DeleteResume) {
    return this.myResumesService.deleteResumeById(payload).pipe(
      tap(() => {
        const allResumes = ctx.getState()?.resumes?.filter((record) => record.id !== payload) ?? []
        ctx.patchState({
          resumes: [...allResumes]
        })
      })
    )
  }

  @Action(SaveNewResume)
  saveNewResume(
    ctx: StateContext<MyResumesStateModel>,
    { type, fileName, generatedResume }: SaveNewResume
  ) {
    const allResumes = ctx.getState()?.resumes ?? []
    return this.myResumesService.saveResume(type, fileName, generatedResume).pipe(
      tap((res: IResume[]) => {
        ctx.patchState({
          resumes: [...res, ...allResumes]
        })
      })
    )
  }

  @Action(UploadResumeSucks)
  uploadResumeSucks(ctx: StateContext<MyResumesStateModel>, { payload }: UploadResume) {
    return this.myResumesService.uploadResumeSucks(payload).pipe(
      tap((res: IResume) => {
        ctx.patchState({
          suckResume: res
        })
      })
    )
  }
  @Action(ShareResumeSucksByEmail)
  shareResumeSucksByEmail(emails: string[], resumeId: string) {
    return this.yourResumeSucksService.shareByEmail(emails, resumeId)
  }

  @Action(GetResumeByID)
  getResumeByID(ctx: StateContext<MyResumesStateModel>, { payload }: GetResumeByID) {
    return this.myResumesService.getResumeById(payload).pipe(
      tap((resume: IResume) => {
        ctx.patchState({ resume })
      })
    )
  }
}
