import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpService } from '@upkey-platform/core/core-data-access'
import { environment, IFileUploadConfig, IS3FileInfo } from '@upkey-platform/core/core-shared'

@Injectable({
  providedIn: 'root'
})
export class FileHandlerService {
  constructor(private http: HttpService) {}

  uploadFile(file: File, options?: IFileUploadConfig): Observable<IS3FileInfo> {
    const body = new FormData()
    body.append('file', file)
    if (options) {
      for (const item in options) {
        body.append(item, (options as any)[item])
      }
    }
    return this.http.post<IS3FileInfo>(`${environment.apiUrl}/file-handler`, body)
  }
}
