<div class="toast-notification">
  <div class="pr-3">
    <svg-icon [key]="options.payload.icon" [color]="options.payload.iconColor" fontSize="40px"></svg-icon>
  </div>
  <div class="flex-1 text-black">
    <div class="font-black" *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
      {{ title }}
    </div>
    <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite"
      [class]="options.messageClass" [innerHTML]="message">
    </div>
    <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite"
      [class]="options.messageClass" [attr.aria-label]="message">
      {{ message }}
    </div>
  </div>
  <div class="pl-3">
    <svg-icon key="close" color="gray" fontSize="14px" (click)="remove()"></svg-icon>
  </div>
</div>
