import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router'
import { Select } from '@ngxs/store'
import { IUser } from '@upkey-platform/accounts/user-shared'
import { map, Observable, take } from 'rxjs'
import { UserAccountState } from '@upkey-platform/accounts/user-data-access'
import { UserAccountPaths } from '@upkey-platform/accounts/user-frontend'

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
  @Select(UserAccountState.currentUser) currentUser$: Observable<IUser>

  constructor(private router: Router) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.currentUser$.pipe(
      take(1),
      map((user) => {
        const canActivate = !!user

        if (!canActivate) {
          this.router.navigate([UserAccountPaths.LoginPath], {
            queryParams: { returnUrl: state.url }
          })
        }

        return canActivate
      })
    )
  }
}
