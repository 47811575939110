import { IPagination } from '@upkey-platform/core/core-shared'
import { ICreateJob, IJob } from '@upkey-platform/jobs/jobs-shared'

export class ChangeCurrentJobStep {
  static readonly type = '[MyJob] Change Current Step'
  constructor(public payload: number) {}
}

export class GetJobs {
  static readonly type = '[MyJob] Get Jobs'
  constructor(public payload?: { paginationInfo?: IPagination; resetWizard?: boolean }) {}
}

export class AddNewJob {
  static readonly type = '[MyJob] Add New Job'
  constructor(public payload: ICreateJob) {}
}

export class GetJobScore {
  static readonly type = '[MyJob] Get Job Score'
  constructor(public payload?: string) {}
}

export class SetResume {
  static readonly type = '[MyJob] Set Resume'
  constructor(public payload?: string) {}
}

export class SetJob {
  static readonly type = '[MyJob] Set Job'
  constructor(public payload: IJob) {}
}

export class SetJobLink {
  static readonly type = '[MyJob] Set Job Link'
  constructor(public payload: string) {}
}

export class ResetAddingJobForms {
  static readonly type = '[MyJob] Reset Adding Job Forms'
}

export class DeleteJob {
  static readonly type = '[MyJob] Job Resume'
  constructor(public payload: string) {}
}
