import { Injector } from '@angular/core'

export class AppInjector {

  private static injector: Injector

  static setInjector(injector: Injector) {
    AppInjector.injector = injector
  }

  static get get(): Injector['get'] {
    if (!AppInjector.injector) {
      throw new Error('Injector was not set correctly.')
    }

    return AppInjector.injector.get.bind(AppInjector.injector)
  }
}
