import { Component, Input } from '@angular/core'
import { NavbarVariation } from '../../data-type/navbar-type'

@Component({
  selector: 'u-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  @Input() variationClass: NavbarVariation = 'main'
}
