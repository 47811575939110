import { Location } from '@angular/common'
import { Injectable, NgZone } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { IUser } from '@upkey-platform/accounts/user-shared'
import { FileHandlerService, TrackService } from '@upkey-platform/core/core-frontend'
import { IAddressResource, IResponse } from '@upkey-platform/core/core-shared'
import { S3Helper } from '@upkey-platform/utils/utils-shared'
import { throwError } from 'rxjs'
import { catchError, concatMap, tap } from 'rxjs/operators'
import { UserAccountService } from '../user-account.service'
import {
  DeleteAccount,
  ForgotPassword,
  GetCurrentUser,
  Login,
  Logout,
  Register,
  ResendEmailVerificationLink,
  ResetPassword,
  SaveSettings,
  SetComeFromExtension,
  UpdateAvatar,
  UpdateNotificationsSettings,
  ValidateReferralCode,
  UpdateIsUserOnboard,
  VerifyEmail
} from './user-account.actions'
import { INavigationState, UserAccountStateModel } from './user-account.model'

@State<UserAccountStateModel>({
  name: 'auth'
})
@Injectable()
export class UserAccountState {
  constructor(
    private userAccountService: UserAccountService,
    private fileHandlerService: FileHandlerService,
    private router: Router,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute,
    private trackService: TrackService,
    private location: Location
  ) {}

  @Selector()
  static isOnboard(state: UserAccountStateModel) {
    return state.user?.isOnboard
  }

  @Selector()
  static shouldShowExtensionModal(state: UserAccountStateModel) {
    return state.user?.isOnboard && state.isFromExtension
  }

  @Selector()
  static isAuthenticated(state: UserAccountStateModel) {
    return state.isAuthenticated
  }

  @Selector()
  static username(state: UserAccountStateModel) {
    return state.user.firstName
  }

  @Selector()
  static referralLink(state: UserAccountStateModel) {
    return UserAccountService.getReferralLink(state.user.referralCode)
  }

  @Selector()
  static userEmail(state: UserAccountStateModel) {
    return state.user.email || ''
  }

  @Selector()
  static currentUser(state: UserAccountStateModel) {
    if (state.user) {
      return {
        ...state.user,
        profileAvatar: state.user.profileAvatar
          ? S3Helper.getLinkForKey(state.user.profileAvatar)
          : undefined
      }
    }
    return
  }

  @Selector()
  static fullAddress(state: UserAccountStateModel) {
    if (state.user?.address) {
      const address: IAddressResource = state.user.address
      const addressParts = [
        address.fullAddress,
        address.city,
        address.state ? address.state + (address.zip ? ` ${address.zip}` : '') : undefined,
        address.zip && !address.state ? address.zip : undefined,
        address.country
      ]
      return addressParts.filter((part) => !!part).join(', ')
    }
    return
  }

  @Selector()
  static error(state: UserAccountStateModel) {
    return state.error
  }

  @Action(GetCurrentUser)
  setCurrentUser(ctx: StateContext<UserAccountStateModel>) {
    return this.userAccountService.getCurrentProfile().pipe(
      tap((user: IUser) => {
        this.trackService.mixpanel({
          fn: 'identify',
          info: {
            identifyId: `UserID-${user.id}`
          }
        })
        ctx.patchState({ user })
      })
    )
  }

  @Action(Register)
  register(ctx: StateContext<UserAccountStateModel>, { payload }: Register) {
    return this.userAccountService.register(payload).pipe(
      tap((user: IUser) => {
        this.trackService.mixpanel({
          fn: 'track',
          info: {
            eventName: 'Sign up'
          }
        })
        this.trackService.mixpanel({
          fn: 'increment',
          info: {
            properties: {
              '# of logins': 1
            }
          }
        })
        this.trackService.mixpanel({
          fn: 'set_once',
          info: {
            properties: {
              'First login': new Date().toUTCString(),
              'Reg. date': new Date().toUTCString()
            }
          }
        })
        this.trackService.mixpanel({
          fn: 'set',
          info: {
            properties: {
              'Last login': new Date().toUTCString()
            }
          }
        })
        ctx.patchState({ user, isAuthenticated: true })
        this.ngZone.run(() => this.navigateToReturnUrl(this.getReturnUrl()))
      })
    )
  }

  @Action(ValidateReferralCode)
  validateReferralCode(ctx: StateContext<UserAccountStateModel>, { payload }: ValidateReferralCode) {
    return this.userAccountService.validateReferralCode(payload)
  }

  @Action(ResendEmailVerificationLink)
  resendEmailVerificationLink() {
    return this.userAccountService.resendEmailVerificationLink()
  }

  @Action(VerifyEmail)
  verifyEmail(ctx: StateContext<UserAccountStateModel>, { payload }: VerifyEmail) {
    return this.userAccountService.verifyEmail(payload).pipe(
      tap((user: IUser) => {
        if (user.emailVerifiedAt) {
          this.trackService.mixpanel({
            fn: 'track',
            info: {
              eventName: 'Sign up - verified'
            }
          })

          this.trackService.googleAnalytics({
            category: 'Account verfied',
            action: 'email confirm'
          })
        }
        ctx.patchState({ user })
      })
    )
  }

  @Action(ForgotPassword)
  forgotPassword(ctx: StateContext<UserAccountStateModel>, { payload }: ForgotPassword) {
    return this.userAccountService.forgotPassword(payload).pipe(
      tap((user: IUser) => {
        ctx.patchState({ user })
      })
    )
  }

  @Action(ResetPassword)
  resetPassword(ctx: StateContext<UserAccountStateModel>, { payload }: ResetPassword) {
    return this.userAccountService.resetPassword(payload).pipe(
      tap((user: IUser) => {
        ctx.patchState({ user, isAuthenticated: true })
        this.ngZone.run(() => this.navigateToReturnUrl(this.getReturnUrl()))
      })
    )
  }

  @Action(Login)
  login(ctx: StateContext<UserAccountStateModel>, { payload }: Login) {
    return this.userAccountService.login(payload).pipe(
      tap((user: IUser) => {
        this.trackService.mixpanel({
          fn: 'identify',
          info: {
            identifyId: `UserID-${user.id}`
          }
        })
        this.trackService.mixpanel({
          fn: 'set',
          info: {
            properties: {
              'Last login': new Date().toUTCString()
            }
          }
        })
        this.trackService.mixpanel({
          fn: 'increment',
          info: {
            properties: {
              '# of logins': 1
            }
          }
        })
        ctx.patchState({ user, isAuthenticated: true })
      })
    )
  }

  @Action(Logout)
  logout(ctx: StateContext<UserAccountStateModel>) {
    return this.userAccountService.logout().pipe(
      tap(() => {
        this.trackService.mixpanel({
          fn: 'reset'
        })
        ctx.patchState({
          user: null,
          success: false,
          isAuthenticated: false,
          error: ''
        })
        // TODO: Replace string path with the correct object
        this.ngZone.run(() => this.router.navigateByUrl('/login'))
      })
    )
  }

  @Action(SaveSettings)
  saveSettings(ctx: StateContext<UserAccountStateModel>, { payload }: SaveSettings) {
    return this.userAccountService.saveSettings(payload).pipe(
      tap((res: IResponse) => {
        if (res.success) {
          const user = ctx.getState().user
          ctx.patchState({ user: { ...user, ...payload } })
        } else {
          throw new Error(res.message)
        }
      }),
      catchError((err) => {
        ctx.patchState({ error: err })
        return throwError(() => err)
      })
    )
  }

  @Action(UpdateNotificationsSettings)
  updateNotificationsSettings(
    ctx: StateContext<UserAccountStateModel>,
    { payload }: UpdateNotificationsSettings
  ) {
    return this.userAccountService.updateNotificationsSettings(payload).pipe(
      tap((res: IResponse) => {
        if (res.success) {
          const user = ctx.getState().user
          ctx.patchState({ user: { ...user, notificationsSetting: payload } })
        } else {
          throw new Error(res.message)
        }
      }),
      catchError((err) => {
        ctx.patchState({ error: err })
        return throwError(() => err)
      })
    )
  }

  @Action(DeleteAccount)
  deleteAccount(ctx: StateContext<UserAccountStateModel>) {
    return this.userAccountService.deleteAccount().pipe(
      tap(() => {
        this.trackService.mixpanel({
          fn: 'track',
          info: {
            eventName: 'User delete account'
          }
        })

        ctx.patchState({
          user: null,
          success: false,
          isAuthenticated: false,
          error: ''
        })
        // TODO: Replace string path with the correct object
        this.ngZone.run(() => this.router.navigateByUrl('/login'))
      })
    )
  }

  @Action(UpdateAvatar)
  updateAvatar(ctx: StateContext<UserAccountStateModel>, { payload }: UpdateAvatar) {
    let imageObjectKey: string
    return this.fileHandlerService.uploadFile(payload, { imageWidth: 240 }).pipe(
      tap((res) => {
        imageObjectKey = res.objectKey
      }),
      concatMap((s3Info) => this.userAccountService.updateAvatar(s3Info)),
      tap((res) => {
        if (res.success) {
          const user = ctx.getState().user
          ctx.patchState({
            user: { ...user, profileAvatar: imageObjectKey }
          })
        }
      }),
      catchError((err) => {
        ctx.patchState({ error: err })
        return throwError(() => err)
      })
    )
  }

  @Action(SetComeFromExtension)
  setComeFromExtension(ctx: StateContext<UserAccountStateModel>, { payload }: SetComeFromExtension) {
    ctx.patchState({ isFromExtension: payload })
  }

  @Action(UpdateIsUserOnboard)
  updateOnboarding(ctx: StateContext<UserAccountStateModel>, { payload }: UpdateIsUserOnboard) {
    const isOnboard = payload
    const user = ctx.getState().user
    ctx.patchState({ user: { ...user, isOnboard } })
  }

  private getReturnUrl(state?: INavigationState): string {
    const fromQuery = (this.activatedRoute.snapshot.queryParams as INavigationState).returnUrl
    if (
      fromQuery &&
      !(
        fromQuery.startsWith(`${window.location.origin}/`) ||
        /\/[^\\/].*/.test(fromQuery) ||
        fromQuery === '/'
      )
    ) {
      // This is an extra check to prevent open redirects.
      throw new Error(
        'Invalid return url. The return url needs to have the same origin as the current page.'
      )
    }
    // for the modal, we replace location hence activatedroute is undefined
    const fromLocation = this.location.path()?.includes('returnUrl')
      ? this.location.path().split('returnUrl=')[1]
      : null
    return (state && state.returnUrl) || fromQuery || fromLocation || 'profile/my-profile'
  }

  private async navigateToReturnUrl(returnUrl: string) {
    await this.router.navigateByUrl(returnUrl, {
      replaceUrl: true
    })
  }
}
