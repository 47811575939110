import { Component, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Select, Store } from '@ngxs/store'
import { IUser } from '@upkey-platform/accounts/user-shared'
import { Observable } from 'rxjs'
import { ProfilePaths } from '../../../features/profile/profile-route-names'
import { UserAccountState } from '@upkey-platform/accounts/user-data-access'

@UntilDestroy()
@Component({
  selector: 'u-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit {
  @Select(UserAccountState.currentUser) currentUser$: Observable<IUser>
  showMenu = false
  profilePaths = ProfilePaths

  constructor(private router: Router, private store: Store) {
    router.events.pipe(untilDestroyed(this)).subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.showMenu = false
      }
    })
  }
  ngOnInit(): void {
    if (!this.store.selectSnapshot<boolean>(UserAccountState.isOnboard)) {
      this.router.navigateByUrl(ProfilePaths.MyProfilePath)
    }
  }
  toggleNavbar() {
    this.showMenu = !this.showMenu
  }
}
