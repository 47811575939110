import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject } from 'rxjs'
import { HttpService } from '@upkey-platform/core/core-data-access'
import { MYJOBS_API_URL } from './my-jobs-api-routes'
import { ICreateJob, IJob } from '@upkey-platform/jobs/jobs-shared'
import { Pagination } from 'nestjs-typeorm-paginate'
import { IPagination } from '@upkey-platform/core/core-shared'
import { IJobMatching } from '@upkey-platform/sovren/sovren-shared'

@Injectable({
  providedIn: 'root'
})
export class MyJobsService {
  private jobMatchedToResumeSubject$ = new BehaviorSubject<boolean>(false)
  jobMatchedToResume$ = this.jobMatchedToResumeSubject$.asObservable()
  constructor(private http: HttpService) {}

  emitJobMatchedToResumeOnCompletion(value: boolean) {
    this.jobMatchedToResumeSubject$.next(value)
  }

  getPaginatedJobs(info?: IPagination): Observable<Pagination<IJob>> {
    return this.http.get<Pagination<IJob>>(MYJOBS_API_URL.getPaginatedJobs, {
      params: {
        page: (info?.page ?? 1).toString(),
        limit: (info?.limit ?? 10).toString()
      }
    })
  }

  createJob(payload: ICreateJob): Observable<IJob> {
    return this.http.post<IJob>(MYJOBS_API_URL.createJob, payload)
  }

  getOneJob(id: string): Observable<IJob> {
    return this.http.get<IJob>(MYJOBS_API_URL.getOneJob(id))
  }

  getJobScore(id: string): Observable<IJobMatching> {
    return this.http.get<IJobMatching>(MYJOBS_API_URL.getJobScore(id))
  }

  deleteJobById(id: string): Observable<IJobMatching> {
    return this.http.delete<IJobMatching>(MYJOBS_API_URL.getOneJob(id))
  }
}
