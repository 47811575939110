import { Injectable } from '@angular/core'
import {
  IChangePassword,
  ICheckUserName,
  IEmailVerificationRequest,
  IPasswordForgotRequest,
  IPasswordResetRequest,
  IUser,
  IUserLoginRequest,
  IUserRegistrationRequest,
  IUserSettings,
  NotificationsSettingResource
} from '@upkey-platform/accounts/user-shared'
import { environment, IResponse, IS3FileInfo } from '@upkey-platform/core/core-shared'
import { Observable } from 'rxjs'
import { HttpService } from '@upkey-platform/core/core-data-access'
import { USERS_API_URL } from './user-account-api-routes'


@Injectable({
  providedIn: 'root'
})
export class UserAccountService {
  constructor(private http: HttpService) {}

  static getReferralLink(referralCode: IUser['referralCode']): string {
    return `${environment.websiteUrl}/register?referral=${referralCode}`
  }

  register(payload: IUserRegistrationRequest): Observable<IUser> {
    return this.http.post<IUser>(USERS_API_URL.register, payload)
  }

  validateReferralCode(payload: IUser['referralCode']) {
    return this.http.get(USERS_API_URL.getValidateReferralCodeUrl(payload))
  }

  resendEmailVerificationLink() {
    return this.http.get(USERS_API_URL.resendEmailVerificationLink)
  }

  verifyEmail(payload: IEmailVerificationRequest): Observable<IUser> {
    return this.http.post<IUser>(USERS_API_URL.verifyEmail, payload)
  }

  forgotPassword(payload: IPasswordForgotRequest) {
    return this.http.post(USERS_API_URL.forgotPassword, payload)
  }

  resetPassword(payload: IPasswordResetRequest): Observable<IUser> {
    return this.http.post<IUser>(USERS_API_URL.resetPassword, payload)
  }

  login(payload: IUserLoginRequest): Observable<IUser> {
    return this.http.post<IUser>(USERS_API_URL.login, payload)
  }

  logout(): Observable<null> {
    return this.http.get(USERS_API_URL.logout)
  }

  getCurrentProfile(): Observable<IUser> {
    return this.http.get<IUser>(USERS_API_URL.me)
  }

  loginViaProvider(provider: 'facebook' | 'google' | 'linkedin'| 'apple'): void {
    // Redirect user to API for auth via provider
    window.location.href = USERS_API_URL.getProviderUrl(provider)
  }

  saveSettings(payload: IUserSettings): Observable<IResponse> {
    return this.http.post<IResponse>(USERS_API_URL.saveSettings, payload)
  }

  updateNotificationsSettings(payload: NotificationsSettingResource): Observable<IResponse> {
    return this.http.post<IResponse>(USERS_API_URL.updateNotificationsSettings, payload)
  }

  changePassword(payload: IChangePassword): Observable<IResponse> {
    return this.http.post<IResponse>(USERS_API_URL.changePassword, payload)
  }

  deleteAccount(): Observable<void> {
    return this.http.delete(USERS_API_URL.deleteAccount)
  }

  checkUsername(payload: ICheckUserName): Observable<IResponse> {
    return this.http.post<IResponse>(USERS_API_URL.checkUsername, payload)
  }

  updateAvatar(payload: IS3FileInfo): Observable<IResponse> {
    return this.http.post<IResponse>(USERS_API_URL.updateAvatar, payload)
  }
}
