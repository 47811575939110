import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class Page404Guard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const navigationExtra = this.router.getCurrentNavigation()?.extras
    if (navigationExtra?.skipLocationChange) {
      return true
    }
    const url = this.router.parseUrl(state.url)
    this.router.navigateByUrl(url, { ...navigationExtra, skipLocationChange: true })
    return false
  }
}
