import { Component, ContentChild, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { IS_PLATFORM_SERVER } from '@upkey-platform/core/core-data-access'
import { TabGroupTemplateDirective } from '@upkey-platform/core/core-frontend'
import { ITabItem } from '../../data-type/tab-group.model'

@UntilDestroy()
@Component({
  selector: 'u-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss']
})
export class TabGroupComponent implements OnInit {
  @ContentChild(TabGroupTemplateDirective)
  tabGroupTemplateContent!: TabGroupTemplateDirective

  @Output() selectedTabEmitter = new EventEmitter<number>()

  @Input() tabItems: ITabItem[] = []
  @Input() tabGroupId = ''
  @Input() tabGroupClass = ''
  @Input() selectedTabClass = ''
  @Input() customTabClass = ''
  @Input() disableRipple = false

  selectedIndex = 0

  constructor(@Inject(IS_PLATFORM_SERVER) private isServer: boolean) {}

  ngOnInit(): void {
    if (!this.isServer) {
      if (this.tabGroupId) {
        this.selectedIndex = Number(sessionStorage.getItem(this.tabGroupId))
      }
    }
  }

  setSelectedIndex(value: number) {
    this.selectedIndex = value
    if (this.tabGroupId) {
      sessionStorage.setItem(this.tabGroupId, this.selectedIndex.toString())
    }
    this.selectedTabEmitter.emit(value)
  }
}
