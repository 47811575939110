import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve } from '@angular/router'
import { ICourseCertificate } from '@upkey-platform/courses/courses-shared'
import { firstValueFrom } from 'rxjs'
import { CareerCourseService } from '@upkey-platform/courses/courses-data-access'
import { abort } from '@upkey-platform/core/core-frontend'

@Injectable({
  providedIn: 'root'
})
export class CertificateResolver implements Resolve<ICourseCertificate | boolean> {
  constructor(private careerCourseService: CareerCourseService) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<ICourseCertificate | boolean> {
    const s3Key = route.paramMap.get('s3Key') as string

    try {
      return await firstValueFrom(this.careerCourseService.getCertificate(s3Key))
    } catch (error) {
      return abort()
    }
  }
}
