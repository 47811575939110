<header
  [ngClass]="{
    'text-lead-1': heading === 'h1',
    'text-lead-2': heading === 'h2',
    'text-lead-3': heading === 'h3',
    'text-lead-4': heading === 'h4',
    'text-lead-5': heading === 'h5',
    'text-lead-6': heading === 'h6',
    hero: heading === 'hero-text'
  }"
  [className]="!weight ? 'font-semibold' : weight"
>
  <h1
    class="{{ colorClass }} font-sans {{ extraClass }}"
    [ngStyle]="{ color: !colorClass ? color : '' }"
  >
    <ng-content></ng-content>
  </h1>
</header>
