<svg
  attr.height="{{ height }}px"
  attr.width="{{ width }}px"
  viewBox="0 0 96 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  class="cursor-pointer"
>
  <g clip-path="url(#clip0)">
    <path
      opacity="0.4"
      d="M23.85 16.95C23.85 20.475 19.875 23.55 11.925 23.55C5.55 23.475 0 20.175 0 16.95C0 13.425 4.35 10.65 11.925 10.65C19.275 10.575 23.85 13.425 23.85 16.95Z"
      [attr.fill]="logoFill"
    />
    <path
      opacity="0.6"
      d="M23.4001 19.05C21.8251 22.2 17.0251 23.25 9.9001 19.725C4.2751 16.95 0.825101 11.55 2.2501 8.62505C3.8251 5.47505 8.9251 4.87505 15.6001 8.17505C22.1251 11.325 24.9751 15.9 23.4001 19.05Z"
      [attr.fill]="logoFill"
    />
    <path
      opacity="0.8"
      d="M22.2751 20.4001C19.5001 22.4251 14.7751 21.0001 10.2001 14.6251C6.45014 9.45011 5.85014 3.15011 8.40014 1.27511C11.1751 -0.749892 15.9001 1.12511 20.3251 7.20011C24.6001 13.0501 25.0501 18.3751 22.2751 20.4001Z"
      [attr.fill]="logoFill"
    />
  </g>
  <path
    d="M34.9053 5V13.4394C34.9053 15.3659 35.6152 16.3401 37.3846 16.3401C39.154 16.3401 39.8639 15.3659 39.8639 13.4394V5H42.7692V13.122C42.7692 17.183 41.3166 19 37.3846 19C33.4526 19 32 17.183 32 13.122V5H34.9053Z"
    [attr.fill]="textFill"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M45.7053 5.00258V18.6634H48.6831V13.6968H52.1608C53.3087 13.7379 54.4193 13.2861 55.2126 12.4554C56.0059 11.6248 56.4061 10.4945 56.3123 9.34969C56.4061 8.2049 56.0059 7.07461 55.2126 6.24393C54.4193 5.41325 53.3087 4.96148 52.1608 5.00258H45.7053ZM51.813 11.1537H48.683V7.58906H51.813C52.1902 7.5387 52.57 7.66146 52.8464 7.92309C53.1228 8.18472 53.2662 8.55723 53.2366 8.93667V9.79522C53.2656 10.1754 53.1224 10.5485 52.8466 10.8117C52.5707 11.0749 52.1914 11.2004 51.813 11.1537Z"
    [attr.fill]="textFill"
  />
  <path
    d="M63.3006 12.705L61.5232 14.8754V18.641H58.5643V5H61.5232V11.5112H61.6424L63.5716 8.87418L66.6388 5.0217H69.9986L65.4032 10.6105L70.4105 18.641H66.9097L63.3006 12.705Z"
    [attr.fill]="textFill"
  />
  <path
    d="M72.2059 18.641V5H81.4851V7.63705H75.2303V10.4152H80.6504V13.0305H75.2303V16.004H81.5393V18.641H72.2059Z"
    [attr.fill]="textFill"
  />
  <path
    d="M87.3616 18.641V13.3018L82.6141 5H86.0146L88.9956 10.426L91.8661 5H95.1782L90.4308 13.291V18.641H87.3616Z"
    [attr.fill]="textFill"
  />
  <defs>
    <clipPath id="clip0">
      <rect width="24" height="24" fill="white" />
    </clipPath>
  </defs>
</svg>
