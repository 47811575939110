import { XhrFactory } from '@angular/common'
import * as xhr2 from 'xhr2'

/* 
  By default, modifying headers is not allowed in XHR2,
  but since this is needed for passing the cookie-based JWT token,
  it is disabled on server-side.
*/
export class ServerXhr implements XhrFactory {
    build(): XMLHttpRequest {
        xhr2.XMLHttpRequest.prototype._restrictedHeaders = false
        return new xhr2.XMLHttpRequest()
    }
}

export const PROTECTED_STATE_STRING = 'PROTECTED'
