<div class="certificate-container relative min-h-screen flex flex-col items-center text-center">
  <div class="w-full flex justify-between items-center py-5 px-5">
    <a routerLink="/">
      <u-logo></u-logo>
    </a>

    <div>
      <u-share-buttons
        [media]="['facebook', 'twitter', 'linkedin', 'copy']"
        url="{{ certificateData.certificate | certificateShareUrl }}"
        variant="black"
        (shareButtonClicked)="showNotification($event)"
      ></u-share-buttons>
    </div>
  </div>

  <img
    class="w-full px-5"
    [src]="certificateData.certificate.image.uploadedFilePath"
    alt="Certificate Image"
  />

  <a
    class="absolute right-6 bottom-16 flex items-center px-3 py-2 rounded-full bg-blue-400 text-white text-center"
    [routerLink]="publicPaths.CCCPagePath"
  >
    <img src="/assets/images/icons/logo.svg" />
    <span class="ml-3 font-medium text-lg leading-xl">Get your own certificate for free</span>
  </a>
</div>
