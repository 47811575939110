import { ViewportScroller } from '@angular/common'
import { Router, Scroll } from '@angular/router'
import { filter, pairwise } from 'rxjs'

export const handleScrollOnNavigation = (router: Router, viewportScroller: ViewportScroller) => {
  router.events
    .pipe(
      filter((e): e is Scroll => e instanceof Scroll),
      pairwise()
    )
    .subscribe((e: Scroll[]) => {
      const current = e[1]
      if (current.position) {
        // Backward navigation
        viewportScroller.scrollToPosition(current.position)
      } else if (current.anchor) {
        // Anchor navigation
        viewportScroller.scrollToAnchor(current.anchor)
      } else {
        //  if Routes don't match, this is actual forward navigation
        // if Routes match we still want to scroll to top for e.g footer links should scroll to top even if user is on current page
        // Default behavior: scroll to top
        viewportScroller.scrollToPosition([0, 0])
      }
    })
}
