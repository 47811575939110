export const UserAccountRoutes = {
  Signup: 'register',
  Login: 'login',
  VerifyEmail: 'verify-email',
  ResetPassword: 'reset-password',
  ForgotPassword: 'forgot-password'
}

export const UserAccountPaths = {
  SignupPath: `/${UserAccountRoutes.Signup}`,
  LoginPath: `/${UserAccountRoutes.Login}`,
  VerifyEmailPath: `/${UserAccountRoutes.VerifyEmail}`,
  ResetPasswordPath: `/${UserAccountRoutes.ResetPassword}`
}
