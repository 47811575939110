import { IUserProfile } from '@upkey-platform/profiles/profile-shared'

export interface ITimelineItem {
  title?: string
  subtitle?: string
  gpa?: number
  text?: string
  description?: string
  startDate?: Date
  endDate?: Date
  noEndDateText?: string
}

export interface IProfileWizardStep {
  sectionNumber: number
  page:
    | 'ContactInformation'
    | 'Hobbies'
    | 'Quotes'
    | 'School'
    | 'Courses'
    | 'Awards'
    | 'WorkExperience'
    | 'Projects'
    | 'Skills'
    | 'Languages'
    | 'WebLinks'
}

export const ProfileWizardSteps: IProfileWizardStep[] = [
  { sectionNumber: 0, page: 'ContactInformation' },
  { sectionNumber: 0, page: 'WebLinks' },
  { sectionNumber: 1, page: 'School' },
  { sectionNumber: 1, page: 'Courses' },
  { sectionNumber: 2, page: 'WorkExperience' },
  { sectionNumber: 2, page: 'Projects' },
  { sectionNumber: 3, page: 'Skills' }
]

export interface ProfileStateModel {
  userProfile: IUserProfile
  currentStepIndex: number
  isOnBoardDialogDisplayed: boolean
  onBoardWizardDisplayed: boolean
  isOnBoardByResume: boolean
}
