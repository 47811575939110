import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { SvgIconsModule } from '@ngneat/svg-icon'
import { UiModule } from '@upkey-platform/ui'
import { SharedModule } from '../../shared/shared.module'
import { FooterComponent } from './public-layout/footer/footer.component'
import { HeaderComponent } from './public-layout/header/header.component'
import { PublicLayoutComponent } from './public-layout/public-layout.component'
import { DirectivesModule } from '@upkey-platform/core/core-frontend'
import { UserUiModule } from '@upkey-platform/accounts/user-frontend'
@NgModule({
  declarations: [PublicLayoutComponent, HeaderComponent, FooterComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    SvgIconsModule,
    UiModule,
    DirectivesModule,
    UserUiModule
  ],
  exports: [PublicLayoutComponent]
})
export class PublicLayoutModule {}
