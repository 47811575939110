import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SvgIconsModule } from '@ngneat/svg-icon'
import { commonIcons } from '@upkey-platform/icons'
import { RouterModule } from '@angular/router'
import { UiModule } from '@upkey-platform/ui'
import { AuthBoxComponent } from './components/auth-box/auth-box.component'
import { SocialLoginComponent } from './components/social-login/social-login.component'
import { GetStartedSectionComponent } from './components/get-started-section/get-started-section.component'
import { TriggerAuthDirective } from './directives/trigger-auth.directive'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SvgIconsModule.forRoot({ icons: commonIcons }),
    UiModule
  ],
  declarations: [
    AuthBoxComponent,
    SocialLoginComponent,
    GetStartedSectionComponent,
    TriggerAuthDirective
  ],
  exports: [AuthBoxComponent, SocialLoginComponent, GetStartedSectionComponent, TriggerAuthDirective]
})
export class UserUiModule {}
