import { Pipe, PipeTransform } from '@angular/core'
import { CertificateResponse } from '@upkey-platform/courses/courses-shared'
import { environment } from '@upkey-platform/core/core-shared'

@Pipe({
  name: 'certificateShareUrl'
})
export class CertificateShareUrlPipe implements PipeTransform {
  transform(certificate: CertificateResponse): string {
    let result = ''

    if (certificate) {
      result = [
        environment.websiteUrl,
        'certificate',
        certificate.image.objectKey.split('.').shift()
      ].join('/')
    }

    return result
  }
}
