import { environment } from '@upkey-platform/core/core-shared'

const getBucketEndpoint = () => {
  // Check if the process object is defined - meaning it currently runs server-side
  return typeof process !== 'undefined' ? process.env?.AWS_BUCKET_ENDPOINT : environment.s3Bucket
}

// TODO: Implement unified S3 service with single source of truth as configuration
export class S3Helper {
  static getLinkForKey(s3Key: string): string {
    return `${getBucketEndpoint()}/${s3Key}`
  }
}
