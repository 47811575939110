<div class="flex items-center justify-center flex-wrap">
  <a
    *ngFor="let item of media"
    [shareButton]="item"
    [url]="url"
    (click)="shareButtonClicked.emit(item)"
    class="flex items-center justify-center cursor-pointer"
    [ngClass]="{
      'w-11 h-7 rounded-0.5 border border-gray-100 md:mr-4 last:mr-0': variant === 'normal',
      'md:mr-5 last:mr-1': variant === 'black'
    }"
  >
    <img
      [src]="generateImageUrl(item)"
      [alt]="item"
      (mouseover)="hoverdItem = item"
      (mouseout)="hoverdItem = undefined"
    />
  </a>
</div>
