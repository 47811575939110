import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { IJob } from '@upkey-platform/jobs/jobs-shared'
import { MyResumesState } from '@upkey-platform/resumes/resumes-data-access'
import { IResume } from '@upkey-platform/resumes/resumes-shared'
import { Pagination } from 'nestjs-typeorm-paginate'
import normalizeUrl from 'normalize-url'
import { catchError, tap, throwError } from 'rxjs'
import { MyJobsService } from '../my-jobs.service'
import {
  AddNewJob,
  ChangeCurrentJobStep,
  DeleteJob,
  GetJobs,
  GetJobScore,
  ResetAddingJobForms,
  SetJob,
  SetJobLink,
  SetResume
} from './my-jobs.actions'
import { JobScoreWizardSteps, MyJobsStateModel } from './my-jobs.model'
import { IJobMatching } from '@upkey-platform/sovren/sovren-shared'
@State<MyJobsStateModel>({
  name: 'myjobs'
})
@Injectable()
export class MyJobsState {
  constructor(private myJobsService: MyJobsService, private store: Store) {}

  @Selector()
  static jobs(state: MyJobsStateModel) {
    return state.jobs?.map((el) => ({
      ...el,
      link: el.link ? normalizeUrl(el.link) : undefined
    }))
  }

  @Selector()
  static paginationMeta(state: MyJobsStateModel) {
    return state.paginationMeta
  }

  @Selector()
  static currentSectionNumber(state: MyJobsStateModel) {
    return JobScoreWizardSteps[state.currentStepIndex].sectionNumber
  }

  @Selector()
  static currentStepIndex(state: MyJobsStateModel) {
    return state.currentStepIndex
  }

  @Selector()
  static resume(state: MyJobsStateModel) {
    return state.resume
  }

  @Selector()
  static job(state: MyJobsStateModel) {
    return state.job
      ? {
          ...state.job,
          link: state.job?.link ? normalizeUrl(state.job.link) : undefined
        }
      : undefined
  }

  @Selector()
  static jobLink(state: MyJobsStateModel) {
    return state.jobLink
  }

  @Selector()
  static jobScoreValue(state: MyJobsStateModel) {
    return state.jobScore?.score
  }

  @Selector()
  static jobScoreSections(state: MyJobsStateModel) {
    return state.jobScore?.sections ?? []
  }

  @Selector()
  static isFinalStep(state: MyJobsStateModel) {
    return state.currentStepIndex + 1 === JobScoreWizardSteps.length
  }

  @Action(ChangeCurrentJobStep)
  changeCurrentStep(ctx: StateContext<MyJobsStateModel>, { payload }: ChangeCurrentJobStep) {
    ctx.patchState({ currentStepIndex: payload })
  }

  @Action(GetJobs)
  getJobs(ctx: StateContext<MyJobsStateModel>, { payload }: GetJobs) {
    return this.myJobsService.getPaginatedJobs(payload?.paginationInfo).pipe(
      tap((res: Pagination<IJob>) => {
        ctx.patchState({
          jobs: res.items ?? [],
          paginationMeta: res.meta
        })
        if (payload?.resetWizard) {
          ctx.patchState({
            currentStepIndex: 0
          })
        }
      }),
      catchError((err) => {
        return throwError(() => err)
      })
    )
  }

  @Action(AddNewJob)
  addNewJob(ctx: StateContext<MyJobsStateModel>, { payload }: AddNewJob) {
    const { jobLink, resume } = ctx.getState()

    return this.myJobsService.createJob({ ...payload, link: jobLink, resumeId: resume?.id }).pipe(
      tap((res: IJob) => {
        ctx.patchState({
          paginationMeta: {
            currentPage: 1
          },
          job: res
        })
      }),
      catchError((err) => {
        return throwError(() => err)
      })
    )
  }

  @Action(GetJobScore)
  getJobScore(ctx: StateContext<MyJobsStateModel>, { payload }: GetJobScore) {
    const jobId = payload ?? ctx.getState()?.job?.id
    if (jobId) {
      return this.myJobsService.getJobScore(jobId).pipe(
        tap((res: IJobMatching) => {
          ctx.patchState({
            jobScore: res
          })
        }),
        catchError((err) => {
          return throwError(() => err)
        })
      )
    }
    return
  }

  @Action(SetResume)
  setResume(ctx: StateContext<MyJobsStateModel>, { payload }: SetResume) {
    ctx.patchState({
      resume: this.store
        .selectSnapshot<IResume[]>(MyResumesState.allResumes)
        ?.find((el) => el.id === payload)
    })
  }

  @Action(SetJob)
  setJob(ctx: StateContext<MyJobsStateModel>, { payload }: SetJob) {
    ctx.patchState({
      job: payload
    })
  }

  @Action(SetJobLink)
  SetJobLink(ctx: StateContext<MyJobsStateModel>, { payload }: SetJobLink) {
    ctx.patchState({
      jobLink: payload
    })
  }

  @Action(ResetAddingJobForms)
  resetAddingJobForms(ctx: StateContext<MyJobsStateModel>) {
    ctx.patchState({
      currentStepIndex: 0,
      jobLink: undefined,
      resume: undefined,
      job: undefined
    })
  }

  @Action(DeleteJob)
  deleteJob(ctx: StateContext<MyJobsStateModel>, { payload }: DeleteJob) {
    return this.myJobsService.deleteJobById(payload).pipe(
      tap(() => {
        const allJobs = ctx.getState()?.jobs?.filter((record) => record.id !== payload) ?? []
        ctx.patchState({
          jobs: [...allJobs]
        })
      })
    )
  }
}
