import { IResumeSucks } from './your-resume-sucks.interface'

export const RESUME_SUCKS_MAPPER: IResumeSucks[] = [
  {
    description: `Congrats! This is a perfect resume; it’s simply amazing, astonishing, out of this world, marvelous, breathtaking, spectacular, impressive, *insert other synonyms for amazing*. This is your daily dose of compliments since that’s obviously what you came here for.<br/> Please feel free to call your family and let them know you shockingly will end up with a great job out of school! Our only suggestion is maybe to add: “I am so perfect your company needs me as a CEO`,
    headline: `Best in the <b>whole universe</b><br>(and still expanding)`,
    seoImageUrl: `/assets/images/meta/OG_fire.png`,
    range: 100
  },
  {
    description: `Well, Well, Well, surprisingly enough, it’s actually good! *plot twist*
    <br/> We can see you went full try-hard mode with this one (*khmm* nerd). It’s a good resume, but you know what we call this category? 2nd best. Don’t worry; we are sure you’ll get to 1st place… eventually.`,
    headline: `<b>Nerd</b> alert!`,
    seoImageUrl: `/assets/images/meta/OG_nerd.png`,
    range: 75
  },
  {
    description: `Is it a resume? Yes. Will it get you the job? Probably not. But hey, at least you tried (we estimate 10 minutes of google search: “How to make a resume?”), so A for effort! <br/>You know when you go to a restaurant, and the food is kinda meh, and you still eat it anyway, but you don’t ever go there again. Well… your resume is kinda like that`,
    headline: `<b>A</b> for effort!`,
    seoImageUrl: `/assets/images/meta/OG_medal.png`,
    range: 50
  },
  {
    description: `If there was a job offer for making resumes that are below average, you may actually be the CEO.
    <br/>We have seen some below-average resumes, and yours is certainly up there.
    It falls perfectly into that category: You must already have a job or really not want one.`,
    headline: `CEO of <b>bad</b> resumes`,
    seoImageUrl: `/assets/images/meta/OG_clown.png`,
    range: 25
  },
  {
    description: `Congratulations, if this were a competition for the worst resume, you’d win for sure. 🏆 You’ve unlocked a special achievement: <br/>“The resume even your mom won’t say is good”. <br/> Don’t worry; there is always a bright side! Your resume may have the lowest score, but we think YOU are a perfect 10! ✨ Just not in resumes.`,
    headline: `Hmm.. Is <b>this</b> a resume?`,
    seoImageUrl: `/assets/images/meta/OG_shit.png`,
    range: 0
  }
]
