import { Injectable } from '@angular/core'
import { IUser } from '@upkey-platform/accounts/user-shared'
import { IUpdateProfile, IUserProfile } from '@upkey-platform/profiles/profile-shared'
import { Observable } from 'rxjs'

import { HttpService } from '@upkey-platform/core/core-data-access'
import { PROFILE_API_URL } from './profile-api-routes'

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private http: HttpService) {}

  getUser(): Observable<IUser> {
    return this.http.get<IUser>(PROFILE_API_URL.getUser)
  }

  updateProfile(payload: IUpdateProfile): Observable<IUserProfile> {
    return this.http.put<IUserProfile>(PROFILE_API_URL.updateProfile, payload)
  }

  overrideProfile(): Observable<IUserProfile> {
    return this.http.post(PROFILE_API_URL.overrideProfile, {})
  }
}
