import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AppLayoutComponent } from './app-layout/app-layout.component'
import { RouterModule } from '@angular/router'
import { SvgIconsModule } from '@ngneat/svg-icon'
import { UiModule } from '@upkey-platform/ui'
import { SidebarComponent } from './app-layout/sidebar/sidebar.component'
import { DirectivesModule } from '@upkey-platform/core/core-frontend'

@NgModule({
  declarations: [AppLayoutComponent, SidebarComponent],
  imports: [CommonModule, RouterModule, SvgIconsModule, UiModule, DirectivesModule],
  exports: [AppLayoutComponent]
})
export class AppLayoutModule {}
