import { Injectable } from '@angular/core'
import { environment } from '@upkey-platform/core/core-shared'
import * as mixpanel from 'mixpanel-browser'
import {
  IMixpanelData,
  IMixpanelInfo,
  IPixelData,
  IGoogleAnalyticsData,
  ILinkedInInsightTagData
} from './track.interface'
import { PixelService } from 'ngx-pixel'
import { GoogleAnalyticsService } from 'ngx-google-analytics'

@Injectable({
  providedIn: 'root'
})
export class TrackService {
  constructor(private pixelservice: PixelService, private gaService: GoogleAnalyticsService) {
    mixpanel.init(environment.mixpanel.token, {
      api_host: environment.mixpanel.proxyServer,
      debug: !environment.production
    })
  }

  mixpanel(data: IMixpanelData) {
    const info = data.info as IMixpanelInfo
    switch (data.fn) {
      case 'identify':
        mixpanel.identify(info.identifyId)
        break
      case 'track':
        mixpanel.track(info.eventName, info.properties)
        break
      case 'time_event':
        mixpanel.time_event(info.eventName)
        break
      case 'register':
        mixpanel.register(info.properties)
        break
      case 'register_once':
        mixpanel.register_once(info.properties)
        break
      case 'set':
        mixpanel.people.set(info.properties)
        break
      case 'set_once':
        mixpanel.people.set_once(info.properties)
        break
      case 'union':
        mixpanel.people.union(info.properties)
        break
      case 'increment':
        mixpanel.people.increment(info.properties)
        break
      case 'reset':
        mixpanel.reset()
        break
    }
  }

  pixel(data: IPixelData) {
    if (data.customEvent) {
      this.pixelservice.trackCustom(data.customEvent, data.properties)
      return
    }

    if (data.standardEvent) {
      this.pixelservice.track(data.standardEvent, data.properties)
    }
  }

  googleAnalytics(data: IGoogleAnalyticsData) {
    this.gaService.event(data.action, data.category, data.label, data.value, data.interaction)
  }

  linkedIn(data: ILinkedInInsightTagData) {
    // Trigger only on production, as there is no testing account for LinkedIn
    if (window['lintrk'] && environment.production) {
      window['lintrk'](data.action, data.payload)
    }
  }
}
