import { Component, Inject, Input, OnInit } from '@angular/core'
import { IS_PLATFORM_SERVER, COOKIE_CONFIG } from '@upkey-platform/core/core-data-access'
import { environment } from '@upkey-platform/core/core-shared'
import { CookieConsentService } from '@upkey-platform/core/core-frontend'
import { trigger, state, style, transition, animate } from '@angular/animations'

@Component({
  selector: 'u-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('*', style({ opacity: 1 })),
      state('void', style({ opacity: 0 })),
      transition('void <=> *', animate('2000ms ease-in-out'))
    ])
  ]
})
export class CookieBannerComponent implements OnInit {
  showCookieBanner = false

  privacyRoute = `${environment.websiteUrl}/privacy-policy`

  @Input() cookieConfig = COOKIE_CONFIG

  constructor(
    @Inject(IS_PLATFORM_SERVER) public isServer: boolean,
    private cookieConsentService: CookieConsentService
  ) {}

  ngOnInit() {
    if (!this.isServer) {
      this.showCookieBanner = !this.cookieConsentService.getCookieValue(this.cookieConfig.name)
    }
  }
  acceptCookies() {
    if (!this.isServer) {
      this.cookieConsentService.setCookie(this.cookieConfig)
      this.showCookieBanner = false
    }
  }
}
