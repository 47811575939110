import { UserAccountRoutes } from '@upkey-platform/accounts/user-frontend'
import { MY_JOBS_ROUTE } from '@upkey-platform/jobs/jobs-shared'

export const ApplicationRoutes = {
  Signup: UserAccountRoutes.Signup,
  Login: UserAccountRoutes.Login,
  Page404: '404',
  Profile: 'profile',
  Dashboard: 'dashboard',
  CareerCourse: 'courses',
  CareerCreationCourse: 'career-creation-course',
  JobPostings: 'jobs',
  MyJobs: MY_JOBS_ROUTE,
  MyResumes: 'dashboard/my-resumes',
  Partners: 'partners',
  Resumes: 'r',
  TermsOfUse: 'terms-of-use',
  PrivacyPolicy: 'privacy-policy',
  YourResumeSucks: 'my-resume-sucks',
  SelfBrandingCourseLive: 'self-branding-course/live',
  // FreeResume: 'free-resume',
  FreeResume: '',
  CBrands: 'cbrands',
  CBrandsOld: 'cbrands-old',
  MagicBox: 'magic-box',

  // Northwestern Mutual Offices
  NwIndy: 'nm-indy',
  NwIndyInternship: 'nm-indy-internship',
  NwIlOld: 'nm-il-old',
  NwIl: 'nm-il',
  NwIlInternship: 'nm-il-internship',
  NwTc: 'nm-twincities',
  NwTcInternship: 'nm-twincities-internship',
  NwTcContactBox: 'nm-twincities-contact',
  NwFishers: 'nm-fishers',
  NwKansasCity: 'nm-kansas-city',
  UclaAnderson: 'ucla-anderson'
}
