import { IUser } from '@upkey-platform/accounts/user-shared'
import { LOCALSTORAGE_KEYS } from '@upkey-platform/core/core-data-access'

export function isFirstVisit(user: IUser) {
  return user.email ? !localStorage.getItem(`${LOCALSTORAGE_KEYS.HAS_VISITED}_${user.email}`) : false
}

export function markUserAsVisited(user: IUser) {
  localStorage.setItem(`${LOCALSTORAGE_KEYS.HAS_VISITED}_${user.email}`, 'true')
}
