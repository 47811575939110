export enum SocialPlatform {
  instagram = 'instagram',
  facebook = 'facebook',
  linkedin = 'linkedin',
  twitter = 'twitter',
  youtube = 'youtube',
  tiktok = 'tiktok',
  github = 'github',
  other = 'other'
}

export const SocialPlatformLabels: { [key in SocialPlatform]: string } = {
  instagram: 'Instagram',
  facebook: 'Facebook',
  linkedin: 'LinkedIn',
  twitter: 'Twitter',
  youtube: 'Youtube',
  tiktok: 'TikTok',
  github: 'Github',
  other: 'Other'
}
