import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ApplicationRoutes } from './app-routes-names'
import { CombinedGuard } from './core/guards/combined.guard'
import { CurrentUserGuard } from './core/guards/current-user.guard'
import { EmailVerifiedGuard } from './core/guards/email-verified.guard'
import { LoggedInGuard } from './core/guards/logged-in.guard'
import { Page404Guard } from './core/guards/page-404.guard'
import { CertificateComponent } from './features/public/certificate/certificate.component'
import { CertificateResolver } from './features/public/certificate/certificate.resolver'
import { PublicRoutes } from './features/public/public-route-names'
import { AppLayoutComponent } from './layout/app-layout/app-layout/app-layout.component'
import { PublicLayoutComponent } from './layout/public-layout/public-layout/public-layout.component'

export const appRoutes: Routes = [
  {
    path: '',
    canActivate: [CurrentUserGuard],
    children: [
      {
        path: '',
        children: [
          {
            path: ApplicationRoutes.JobPostings,
            loadChildren: () => import('./features/jobs/jobs.module').then((m) => m.JobsModule)
          },
          // {
          //   path: ApplicationRoutes.FreeResume,
          //   loadChildren: () =>
          //     import('./features/free-resume/free-resume.module').then((m) => m.FreeResumeModule)
          // },
          {
            component: PublicLayoutComponent,
            path: '',
            loadChildren: () => import('./features/public/public.module').then((m) => m.PublicModule)
          },
          {
            path: '',
            loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule)
          },
          {
            path: ApplicationRoutes.CBrandsOld,
            data: { title: 'Constellation Careers', enterprise: ApplicationRoutes.CBrandsOld },
            loadChildren: () =>
              import('./features/enterprise/enterprise.module').then((m) => m.EnterpriseModule)
          },
          {
            path: ApplicationRoutes.CBrands,
            data: { title: 'Constellation Careers' },
            loadChildren: () =>
              import('./features/enterprise/enterprise.module').then((m) => m.EnterpriseModule)
          },
          {
            path: ApplicationRoutes.NwIndy,
            data: { title: 'Northwestern Mutual', enterprise: ApplicationRoutes.NwIndy },
            loadChildren: () =>
              import('./features/enterprise/enterprise.module').then((m) => m.EnterpriseModule)
          },
          {
            path: ApplicationRoutes.NwTcInternship,
            data: { title: 'Northwestern Mutual', enterprise: ApplicationRoutes.NwTcInternship },
            loadChildren: () =>
              import('./features/enterprise/enterprise.module').then((m) => m.EnterpriseModule)
          },
          {
            path: ApplicationRoutes.NwTc,
            data: { title: 'Northwestern Mutual', enterprise: ApplicationRoutes.NwTc },
            loadChildren: () =>
              import('./features/enterprise/enterprise.module').then((m) => m.EnterpriseModule)
          },
          {
            path: ApplicationRoutes.NwTcContactBox,
            data: { title: 'Northwestern Mutual', enterprise: ApplicationRoutes.NwTcContactBox },
            loadChildren: () =>
              import('./features/enterprise/enterprise.module').then((m) => m.EnterpriseModule)
          },
          {
            path: ApplicationRoutes.NwIl,
            data: { title: 'Northwestern Mutual', enterprise: ApplicationRoutes.NwIl },
            loadChildren: () =>
              import('./features/enterprise/enterprise.module').then((m) => m.EnterpriseModule)
          },
          {
            path: ApplicationRoutes.NwFishers,
            data: { title: 'Northwestern Mutual', enterprise: ApplicationRoutes.NwFishers },
            loadChildren: () =>
              import('./features/enterprise/enterprise.module').then((m) => m.EnterpriseModule)
          },
          {
            path: ApplicationRoutes.NwKansasCity,
            data: { title: 'Northwestern Mutual', enterprise: ApplicationRoutes.NwKansasCity },
            loadChildren: () =>
              import('./features/enterprise/enterprise.module').then((m) => m.EnterpriseModule)
          },
          {
            path: ApplicationRoutes.UclaAnderson,
            data: {
              title: 'UCLA Anderson School of Management',
              enterprise: ApplicationRoutes.UclaAnderson
            },
            loadChildren: () =>
              import('./features/enterprise/enterprise.module').then((m) => m.EnterpriseModule)
          },
          {
            path: ApplicationRoutes.NwIlInternship,
            data: { title: 'Northwestern Mutual', enterprise: ApplicationRoutes.NwIlInternship },
            loadChildren: () =>
              import('./features/enterprise/enterprise.module').then((m) => m.EnterpriseModule)
          },
          {
            path: ApplicationRoutes.NwIndyInternship,
            data: {
              title: 'Northwestern Mutual Internship',
              enterprise: ApplicationRoutes.NwIndyInternship
            },
            loadChildren: () =>
              import('./features/enterprise/enterprise.module').then((m) => m.EnterpriseModule)
          },
          {
            path: ApplicationRoutes.MagicBox,
            data: { title: 'MagicBox', enterprise: 'magic-box' },
            loadChildren: () =>
              import('./features/enterprise/enterprise.module').then((m) => m.EnterpriseModule)
          },
          {
            component: PublicLayoutComponent,
            canActivate: [Page404Guard],
            path: ApplicationRoutes.Page404,
            loadChildren: () =>
              import('./features/page-404/page-404.module').then((m) => m.Page404Module)
          }
        ]
      },
      {
        path: ApplicationRoutes.Partners,
        loadChildren: () =>
          import('./features/universities/universities.module').then((m) => m.UniversitiesModule)
      },
      {
        path: ApplicationRoutes.YourResumeSucks,
        loadChildren: () =>
          import('./features/your-resume-sucks/your-resume-sucks.module').then(
            (m) => m.YourResumeSucksModule
          )
      }
    ]
  },

  {
    path: ApplicationRoutes.Resumes,
    loadChildren: () => import('./features/resume/resume.module').then((m) => m.ResumeModule)
  },

  {
    path: '',
    canActivate: [CombinedGuard],
    data: {
      guards: [CurrentUserGuard, LoggedInGuard, EmailVerifiedGuard]
    },
    component: AppLayoutComponent,
    children: [
      {
        path: ApplicationRoutes.CareerCourse,
        loadChildren: () =>
          import('./features/career-course/career-course.module').then((m) => m.CareerCourseModule)
      },
      {
        path: ApplicationRoutes.MyJobs,
        loadChildren: () => import('./features/my-jobs/my-jobs.module').then((m) => m.MyJobsModule)
      },
      {
        path: ApplicationRoutes.MyResumes,
        loadChildren: () =>
          import('./features/my-resumes/my-resumes.module').then((m) => m.MyResumesModule)
      },
      {
        path: ApplicationRoutes.Profile,
        loadChildren: () => import('./features/profile/profile.module').then((m) => m.ProfileModule)
      },
      {
        path: ApplicationRoutes.SelfBrandingCourseLive,
        loadChildren: () =>
          import('./features/self-branding-course/self-branding-course.module').then(
            (m) => m.SelfBrandingCourseModule
          )
      }
    ]
  },

  {
    path: PublicRoutes.Certificate,
    component: CertificateComponent,
    resolve: { certificate: CertificateResolver }
  },

  { path: '**', redirectTo: '404' }
]

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(appRoutes, {
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
