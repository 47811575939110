import { Directive, Inject, Input, TemplateRef, ViewContainerRef } from '@angular/core'
import { IS_PLATFORM_SERVER } from '@upkey-platform/core/core-data-access'

@Directive({
  selector: '[uIfPlatform]'
})
export class IfPlatformDirective {
  constructor(
    @Inject(IS_PLATFORM_SERVER) private isServer: boolean,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input() set uIfPlatform(platform: 'server' | 'browser') {
    if (platform === 'server' && this.isServer) {
      this.viewContainer.createEmbeddedView(this.templateRef)
    } else if (platform === 'browser' && !this.isServer) {
      this.viewContainer.createEmbeddedView(this.templateRef)
    } else {
      this.viewContainer.clear()
    }
  }
}
