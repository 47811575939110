import { Component, Input } from '@angular/core'

@Component({
  selector: 'u-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {
  @Input() width = '96'
  @Input() height = '24'
  @Input() textFill = '#1B2124'
  @Input() logoFill = '#00A6FF'
}
