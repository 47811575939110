import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, EventEmitter, Output } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { Select, Store } from '@ngxs/store'
import { IUser } from '@upkey-platform/accounts/user-shared'
import {
  CareerReadinessCourseSlug,
  SelfBrandingCourseSlug
} from '@upkey-platform/courses/courses-shared'
import { Observable } from 'rxjs'
import { ApplicationRoutes } from '../../../../app-routes-names'
import { ILessonSlugInfo, CareerCourseState } from '@upkey-platform/courses/courses-data-access'
import { ProfilePaths } from '../../../../features/profile/profile-route-names'
import { Logout, UserAccountState } from '@upkey-platform/accounts/user-data-access'

@UntilDestroy()
@Component({
  selector: 'u-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('toggleSideFooter', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate('150ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('150ms', style({ opacity: 0 }))])
    ])
  ]
})
export class SidebarComponent {
  readonly profilePaths = ProfilePaths
  readonly appPaths = ApplicationRoutes
  showSideFooter = false
  @Output() closeSideBar = new EventEmitter<boolean>()
  @Select(UserAccountState.currentUser) currentUser$: Observable<IUser>

  constructor(private store: Store) {}

  logOut() {
    this.store.dispatch(new Logout())
  }

  toggle() {
    this.showSideFooter = !this.showSideFooter
  }

  getCarrerCourseLink() {
    return this.getCourseLink(CareerReadinessCourseSlug)
  }

  getSelfBrandingCourseLink() {
    return this.getCourseLink(SelfBrandingCourseSlug)
  }

  private getCourseLink(slug: string) {
    const basePath = [ApplicationRoutes.CareerCourse, slug]
    const currentlessonInfo = this.store.selectSnapshot<ILessonSlugInfo>(
      CareerCourseState.currentlessonInfo
    )
    if (currentlessonInfo) {
      const index = (currentlessonInfo && currentlessonInfo[slug] && currentlessonInfo[slug]?.index) || 0
      basePath.push((index + 1).toString())
    }
    return basePath
  }

  close() {
    this.closeSideBar.emit(true)
    this.showSideFooter = false
  }
}
