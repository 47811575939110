import { Router } from '@angular/router'
import { AppInjector } from '@upkey-platform/core/core-frontend'
import { HttpStatusCode } from '@angular/common/http'

async function isFulfilled(condition: AbortCondition) {
  return typeof condition === 'function' ? await condition() : condition
}

export type AbortCondition = (() => Promise<boolean> | boolean) | boolean

export type AbortCode = HttpStatusCode.NotFound

/**
 * Stop code execution and show user error page of given code (e.g. 404 or 403).
 */
export function abort(code?: AbortCode) {
  return AppInjector.get(Router)
    .navigate(
      [code || HttpStatusCode.NotFound],
      { skipLocationChange: true }
    )
}

/**
 * If condition is fulfilled, then stop code execution and show user error page of given code (e.g. 404 or 403).
 */
export async function abortIf(condition: AbortCondition, code?: AbortCode) {
  return await isFulfilled(condition) ? abort(code) : false
}

/**
 * If condition isn't fulfilled, then stop code execution and show user error page of given code (e.g. 404 or 403).
 */
export async function abortUnless(condition: AbortCondition, code?: AbortCode) {
  return await isFulfilled(condition) ? false : abort(code)
}
