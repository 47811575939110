import { Injectable } from '@angular/core'
import { CanActivate } from '@angular/router'
import { Select, Store } from '@ngxs/store'
import { IUser } from '@upkey-platform/accounts/user-shared'
import { catchError, map, Observable, of, switchMap, take } from 'rxjs'
import { GetCurrentUser, UserAccountState } from '@upkey-platform/accounts/user-data-access'

@Injectable({
  providedIn: 'root'
})
export class CurrentUserGuard implements CanActivate {
  @Select(UserAccountState.currentUser) currentUser$: Observable<IUser>

  constructor(private store: Store) {}
  canActivate(): Observable<boolean> {
    return this.currentUser$.pipe(
      switchMap((user) => {
        return user
          ? of(user)
          : this.store.dispatch(new GetCurrentUser()).pipe(map((state) => state.user))
      }),
      catchError(() => {
        return of(null)
      }),
      take(1),
      map(() => true)
    )
  }
}
