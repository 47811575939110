import { Injectable } from '@angular/core'
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr'

@Injectable({
  providedIn: 'root'
})
export class ToastNotificationService {
  constructor(private toastr: ToastrService) {}

  info(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    override = {
      payload: {
        icon: 'alert',
        iconColor: '#00A6FF',
      },
      ...override,
    }
    
    return this.toastr.show(message, title, override)
  }
  
  success(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    override = {
      payload: {
        icon: 'alert',
        iconColor: '#238636',
      },
      ...override,
    }
    
    return this.toastr.show(message, title, override)
  }
  
  error(message?: string, title?: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    override = {
      payload: {
        icon: 'alert',
        iconColor: '#FF0000',
      },
      ...override,
    }

    return this.toastr.show(message, title, override)
  }
}
