import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ICourseCertificate } from '@upkey-platform/courses/courses-shared'
import { SeoSocialShareService } from 'ngx-seo'
import { ToastNotificationService } from '@upkey-platform/core/core-frontend'
import { ShareMediaType } from '@upkey-platform/ui'
import { PublicPaths } from '../public-route-names'

@Component({
  selector: 'u-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit {
  certificateData: ICourseCertificate
  publicPaths = PublicPaths

  constructor(
    private seoSocialShareService: SeoSocialShareService,
    private route: ActivatedRoute,
    private notificationService: ToastNotificationService
  ) {}

  ngOnInit(): void {
    this.certificateData = this.route.snapshot.data.certificate

    this.seoSocialShareService.setData({
      title: 'Powerful Career Creation | Certificate',
      type: 'website',
      description: 'Upkey’s course Certificate',
      image: this.certificateData.certificate.image.uploadedFilePath,
      imageAuxData: {
        secureUrl: this.certificateData.certificate.image.uploadedFilePath,
        width: 759,
        height: 582
      }
    })
  }

  showNotification(media: ShareMediaType) {
    if (media === 'copy') {
      this.notificationService.info('Link to certificate was saved to you clipboard')
    }
  }
}
