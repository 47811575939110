<div class="modal-container relative py-5.5 px-7">
  <!-- Close Btn -->
  <div class="absolute cursor-pointer top-3.5 right-3.5" (click)="dialogRef.close()">
    <svg-icon color="#CCCCCC" key="close"></svg-icon>
  </div>

  <div *ngIf="data.subtitle" class="text-blue-400 font-semibold text-2.5xl leading-3xl">
    {{ data.title }}
  </div>

  <div *ngIf="data.subtitle" class="mt-3 text-black font-normal text-lg leading-xl">
    {{ data.subtitle }}
  </div>

  <div class="mt-4 flex items-center">
    <u-button class="mr-2" (click)="dialogRef.close()" variant="ghost" size="small">Cancel</u-button>

    <u-button (click)="dialogRef.close(true)" [variant]="data.type" size="small">
      {{ data.okButtonTitle }}
    </u-button>
  </div>
</div>
