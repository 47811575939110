import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { OneTimeDirective } from './one-time.directive'
import { AutofocusDirective } from './auto-focus.directive'


@NgModule({
  declarations: [
    OneTimeDirective, AutofocusDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    OneTimeDirective, AutofocusDirective
  ]
})
export class DirectivesModule {}
