import {
  IEmailVerificationRequest,
  IPasswordForgotRequest,
  IPasswordResetRequest,
  IUser,
  IUserLoginRequest,
  IUserRegistrationRequest,
  IUserSettings,
  NotificationsSettingResource
} from '@upkey-platform/accounts/user-shared'

export class GetCurrentUser {
  static readonly type = '[Auth] Get Current User'
}

export class Register {
  static readonly type = '[Auth] Register'
  constructor(public payload: IUserRegistrationRequest) {}
}

export class ValidateReferralCode {
  static readonly type = '[Auth] Validate Referral Code'
  constructor(public payload: IUser['referralCode']) {}
}

export class ResendEmailVerificationLink {
  static readonly type = '[Auth] Resend Email Verification Link'
}

export class VerifyEmail {
  static readonly type = '[Auth] Verify Email'
  constructor(public payload: IEmailVerificationRequest) {}
}

export class Login {
  static readonly type = '[Auth] Login'
  constructor(public payload: IUserLoginRequest) {}
}

export class Logout {
  static readonly type = '[Auth] Logout'
}

export class ForgotPassword {
  static readonly type = '[Auth] Forgot Password'
  constructor(public payload: IPasswordForgotRequest) {}
}

export class ResetPassword {
  static readonly type = '[Auth] Reset Password'
  constructor(public payload: IPasswordResetRequest) {}
}

export class SaveSettings {
  static readonly type = '[Auth] Save Settings'
  constructor(public payload: IUserSettings) {}
}

export class UpdateNotificationsSettings {
  static readonly type = '[Auth] Update Notifications Settings'
  constructor(public payload: NotificationsSettingResource) {}
}

export class DeleteAccount {
  static readonly type = '[Auth] Delete Account'
}

export class UpdateAvatar {
  static readonly type = '[Auth] Update Avatar'
  constructor(public payload: File) {}
}

export class SetComeFromExtension {
  static readonly type = '[Auth] Set Come From Extension'
  constructor(public payload: boolean) {}
}

export class UpdateIsUserOnboard {
  static readonly type = '[Auth] Update Is User Onboard Avatar'
  constructor(public payload: boolean) {}
}
