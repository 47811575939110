export const PublicRoutes = {
  Certificate: 'certificate/:s3Key',
  JobsPage: 'jobs',
  CCCPage: 'career-creation-course',
  TermsOfUsePage: 'terms-of-use',
  PrivacyPolicyPage: 'privacy-policy',
  AboutPage: 'about',
  SelfBrandingCoursePage: 'self-branding-course',
  FreeResumePage: 'free-resume'
}

export const PublicPaths = {
  CertificatePath: (s3Key: string) => `/${PublicRoutes.Certificate.replace(':s3Key', s3Key)}`,
  JobsPagePath: `/${PublicRoutes.JobsPage}`,
  CCCPagePath: `/${PublicRoutes.CCCPage}`,
  TermsOfUsePath: `/${PublicRoutes.TermsOfUsePage}`,
  PrivacyPolicyPath: `/${PublicRoutes.PrivacyPolicyPage}`,
  AboutPath: `/${PublicRoutes.AboutPage}`,
  SelfBrandingCoursePath: `/${PublicRoutes.SelfBrandingCoursePage}`,
  FreeResumePath: `/${PublicRoutes.FreeResumePage}`
}
