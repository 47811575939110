import { ApplicationRoutes } from '../../app-routes-names'

export const ProfileRoutes = {
  Settings: 'settings',
  MyProfile: 'my-profile',
  Referrals: 'referrals'
}

const profile = ApplicationRoutes.Profile

export const ProfilePaths = {
  SettingsPath: `/${profile}/${ProfileRoutes.Settings}`,
  MyProfilePath: `/${profile}/${ProfileRoutes.MyProfile}`,
  ReferralPath: `/${profile}/${ProfileRoutes.Referrals}`
}
