import { Injectable } from '@angular/core'
import { HttpService } from '@upkey-platform/core/core-data-access'
import { MY_RESUMES_API_URL } from './my-resumes-api-routes'
import { IResume, ResumeGenerationType } from '@upkey-platform/resumes/resumes-shared'
import { Observable } from 'rxjs'
import { Pagination } from 'nestjs-typeorm-paginate'
import { IPagination } from '@upkey-platform/core/core-shared'
import { IResumeTemplate } from './+state/my-resumes.model'
import { MatDialog } from '@angular/material/dialog'

@Injectable({
  providedIn: 'root'
})
export class MyResumesService {
  constructor(private http: HttpService, private dialogService: MatDialog) {}

  getResumes(info?: IPagination): Observable<Pagination<any>> {
    return this.http.get<Pagination<any>>(MY_RESUMES_API_URL.resume, {
      params: {
        page: (info?.page ?? 1).toString(),
        limit: (info?.limit ?? 10).toString()
      }
    })
  }

  getResumeDetailsById(id: string) {
    return this.http.get(`${MY_RESUMES_API_URL.resume}/${id}/details`)
  }

  getResumeScoreById(id: string) {
    return this.http.get(`${MY_RESUMES_API_URL.resume}/${id}/score`)
  }

  downloadResumeById(id: string) {
    return this.http.get(`${MY_RESUMES_API_URL.resume}/${id}/download`)
  }

  deleteResumeById(id: string) {
    return this.http.delete(`${MY_RESUMES_API_URL.resume}/${id}`)
  }

  generateResumeTemplate(template: string): Observable<IResumeTemplate> {
    const format = 'all'
    return this.http.post(MY_RESUMES_API_URL.generateResume, {
      template,
      format
    })
  }

  getAllResumes(): Observable<IResume[]> {
    return this.http.get<IResume[]>(MY_RESUMES_API_URL.getAllResumes)
  }

  uploadResume(resume: File): Observable<IResume> {
    const body = new FormData()
    body.append('file', resume)

    return this.http.post(MY_RESUMES_API_URL.upload, body)
  }

  saveResume(type: ResumeGenerationType, fileName: string, generatedResume: IResumeTemplate) {
    return this.http.post(MY_RESUMES_API_URL.saveResume, {
      type,
      fileName,
      generatedResume
    })
  }

  getMyResumeSucksDetailsById(id: string) {
    return this.http.get(`${MY_RESUMES_API_URL.resume}/${id}?context=MRS`)
  }

  uploadResumeSucks(resume: File): Observable<IResume> {
    const body = new FormData()
    body.append('file', resume)
    return this.http.post(`${MY_RESUMES_API_URL.upload}?context=MRS`, body)
  }

  getResumeById(id: string) {
    return this.http.get(`${MY_RESUMES_API_URL.resume}/${id}`)
  }
}
