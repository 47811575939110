import { IPagination } from '@upkey-platform/core/core-shared'
import {
  IResume,
  ResumeGenerationTemplate,
  ResumeGenerationType
} from '@upkey-platform/resumes/resumes-shared'
import { IResumeTemplate } from './my-resumes.model'

export class GetMyResumes {
  static readonly type = '[My Resumes] Get my resumes'
  constructor(public payload?: { paginationInfo?: IPagination }) {}
}

export class GetMyResumeById {
  static readonly type = '[My Resumes] Get my resume by Id'
  constructor(public payload: string) {}
}

export class GetMyResumeScoreById {
  static readonly type = '[My Resumes] Get my resume score by Id'
  constructor(public payload: string) {}
}
export class GetAllResumes {
  static readonly type = '[MyResume] Get All Resumes'
}

export class UploadResume {
  static readonly type = '[MyResume] Upload Resume'
  constructor(public payload: File) {}
}

export class SaveNewResume {
  static readonly type = '[MyResume] Save Resume'
  constructor(
    public type: ResumeGenerationType,
    public fileName: string,
    public generatedResume: IResumeTemplate
  ) {}
}

export class DeleteResume {
  static readonly type = '[MyResume] Delete Resume'
  constructor(public payload: string) {}
}

export class GetResumeDetails {
  static readonly type = '[MyResume] Get Resume Details'
  constructor(public payload: IResume['id']) {}
}

export class SetResumeTemplateById {
  static readonly type = '[MyResume] Set Resume Template by ID'
  constructor(public payload: ResumeGenerationTemplate) {}
}

export class SetResumeTemplate {
  static readonly type = '[MyResume] Set Resume Template'
  constructor(public template: IResumeTemplate) {}
}

export class GetMyResumeSucksDetails {
  static readonly type = '[MyResume] Get Resume Sucks Details'
  constructor(public payload: IResume['id']) {}
}

export class UploadResumeSucks {
  static readonly type = '[MyResume] Upload Resume Sucks'
  constructor(public payload: File) {}
}
export class ShareResumeSucksByEmail {
  static readonly type = '[MyResume] Share Resume Sucks Via Email'
  constructor(public emails: string[], public resumeId: string) {}
}

export class GetResumeByID {
  static readonly type = '[MyResume] Get Resume By ID'
  constructor(public payload: IResume['id']) {}
}
