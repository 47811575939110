import { Injectable } from '@angular/core'
import { CanActivate, Router, UrlTree } from '@angular/router'
import { Select } from '@ngxs/store'
import { IUser } from '@upkey-platform/accounts/user-shared'
import { map, Observable, take } from 'rxjs'
import { UserAccountState } from '@upkey-platform/accounts/user-data-access'
import { UserAccountPaths } from '@upkey-platform/accounts/user-frontend'

@Injectable({
  providedIn: 'root'
})
export class EmailVerifiedGuard implements CanActivate {
  @Select(UserAccountState.currentUser) currentUser$: Observable<IUser>

  constructor(private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.currentUser$.pipe(
      take(1),
      map((user) => {
        const canActivate = !!user.emailVerifiedAt

        if (!canActivate) {
          this.router.navigate([UserAccountPaths.VerifyEmailPath])
        }

        return canActivate
      })
    )
  }
}
