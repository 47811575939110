import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatExpansionModule } from '@angular/material/expansion'
import { RouterModule } from '@angular/router'
import { SvgIconsModule } from '@ngneat/svg-icon'
import { NgxsModule } from '@ngxs/store'
import { UiModule } from '@upkey-platform/ui'
import { MyJobsState } from '@upkey-platform/jobs/jobs-data-access'
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe'
import { SearchFilterPipe } from './pipes/search-filter.pipe'
import { CertificateShareUrlPipe } from './pipes/certificate-share-url.pipe'
import { MonthYearDate } from './pipes/month-year-date.pipe'
import { TestimonialsComponent } from './component/testimonials/testimonials.component'
import { CarouselModule } from 'ngx-owl-carousel-o'

@NgModule({
  declarations: [
    SearchFilterPipe,
    EnumToArrayPipe,
    CertificateShareUrlPipe,
    MonthYearDate,
    TestimonialsComponent
  ],
  imports: [
    RouterModule,
    SvgIconsModule,
    CommonModule,
    UiModule,
    MatExpansionModule,
    MatDialogModule,
    FormsModule,
    CarouselModule,
    ReactiveFormsModule,
    NgxsModule.forFeature([MyJobsState])
  ],
  exports: [
    SearchFilterPipe,
    EnumToArrayPipe,
    CertificateShareUrlPipe,
    MonthYearDate,
    TestimonialsComponent
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
  ]
})
export class SharedModule {}
