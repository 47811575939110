import { IUpdateProfile } from '@upkey-platform/profiles/profile-shared'

export class ChangeCurrentStep {
  static readonly type = '[Profile] Change Current Step'
  constructor(public payload: number) {}
}

export class GetUserProfile {
  static readonly type = '[Profile] Get User Profile'
}

export class UpdateProfile {
  static readonly type = '[Profile] Update Profile'
  constructor(public payload: { profile: IUpdateProfile; isOnBoard?: boolean }) {}
}

export class OnBoardDialogDisplayed {
  static readonly type = '[Profile] On Board Dialog Displayed'
}

export class SetOnBoardWizardDisplayed {
  static readonly type = '[Profile] Set On Board Wizard Displayed'
  constructor(public payload: boolean) {}
}

export class SetOnBoardByResume {
  static readonly type = '[Profile] Set On Board By Resume'
  constructor(public payload: boolean) {}
}

export class OverrideProfile {
  static readonly type = '[Profile] Override Profile'
}
