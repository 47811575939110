import { Component, EventEmitter, Input, Output } from '@angular/core'

export type ShareMediaType = 'facebook' | 'twitter' | 'linkedin' | 'copy'

@Component({
  selector: 'u-share-buttons',
  templateUrl: './share-buttons.component.html',
  styleUrls: ['./share-buttons.component.scss']
})
export class ShareButtonsComponent {
  @Input() variant: 'normal' | 'black' = 'normal'
  @Input() url: string
  @Input() media: Array<ShareMediaType> = []
  @Output() shareButtonClicked = new EventEmitter<ShareMediaType>()

  hoverdItem?: string

  generateImageUrl(item: string) {
    const url = '/assets/images/icons/share-social/'
    if (this.hoverdItem === item && this.variant === 'black') {
      return url + `${item}-black-hover.svg`
    }
    return url + `${item}${this.variant === 'black' ? '-black' : ''}.svg`
  }
}
