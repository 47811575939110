import { Directive, HostListener, Input } from '@angular/core'
import { Params, Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Select } from '@ngxs/store'
import { UserAccountState } from '@upkey-platform/accounts/user-data-access'
import { IUser, StartScreenSource } from '@upkey-platform/accounts/user-shared'
import { LOCALSTORAGE_KEYS } from '@upkey-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { UserAccountRoutes } from '../../constants/user-account-route-names'
import { LocalStorageService } from '../services/local-storage.service'

@UntilDestroy()
@Directive({
  selector: '[uTriggerAuth]'
})
export class TriggerAuthDirective {
  @Input() redirectAfterLogin = '/profile/my-profile'
  @Input() redirectAfterSignUp = '/profile/my-profile'
  @Input() startScreen: 'login' | 'sign-up' = 'login'
  @Input() source: StartScreenSource = StartScreenSource.direct
  @Input() redirectQueryParam: Params = {}
  @Select(UserAccountState.currentUser) currentUser$: Observable<IUser>
  currentUser: IUser

  readonly loginRoute = `/${UserAccountRoutes.Login}`
  readonly signupRoute = `/${UserAccountRoutes.Signup}`

  constructor(private router: Router, private localStorage: LocalStorageService) {
    this.currentUser$.pipe(untilDestroyed(this)).subscribe((res) => (this.currentUser = res))
  }
  @HostListener('click') triggerAuthProcess() {
    try {
      this.localStorage.setItem(
        LOCALSTORAGE_KEYS.REDIRECT_ROUTE,
        {
          redirectAfterLogin: this.redirectAfterLogin,
          redirectAfterSignUp: this.redirectAfterSignUp,
          source: this.source,
          redirectQueryParam: this.redirectQueryParam
        },
        false
      )
    } catch (e) {
      console.error(`Can't set redirect URL and source`, e)
    }

    this.router.navigate([this.startScreen])

    if (!this.currentUser) {
      if (this.startScreen === 'login') {
        this.router.navigate([this.loginRoute])
      }

      if (this.startScreen === 'sign-up') {
        this.router.navigate([this.signupRoute])
      }
    } else {
      if (this.redirectAfterLogin) {
        this.router.navigate([this.redirectAfterLogin])
      }
    }
  }
}
