import { IQuizAnswerRequest } from '@upkey-platform/courses/courses-shared'
import { ILessonsStatus } from '../career-course.interface'

export class GetCourse {
  static readonly type = '[CareerCourse] Get'
  constructor(public payload: string) {}
}

export class SetCurrentLesson {
  static readonly type = '[CareerCourse] Set Current Lesson'
  constructor(
    public payload: {
      index: number
      autoPlay: boolean
    }
  ) {}
}

export class ResetCurrentLesson {
  static readonly type = '[CareerCourse] Reset Current Lesson'
}

export class SetFirstTimePlayVideo {
  static readonly type = '[CareerCourse] Set First Time Play Video'
}

export class CheckQuiz {
  static readonly type = '[CareerCourse] Check Quiz'
  constructor(public payload: IQuizAnswerRequest) {}
}

export class IsCompleted {
  static readonly type = '[CareerCourse] Is Completed'
  constructor(public payload: string) {}
}

export class ChangeLessonStatus {
  static readonly type = '[CareerCourse] Change Lesson Status'
  constructor(
    public payload: {
      courseId: string
      lessonIndex: number
      status: ILessonsStatus
    }
  ) {}
}
