import { ErrorHandler } from '@angular/core'
import * as Sentry from '@sentry/angular'

export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      return window.location.reload();
    }

    Sentry.setExtra('error', error) // regarding https://forum.sentry.io/t/error-object-details-not-showing-up-in-sentry/7081/3
    Sentry.captureException(error.originalError || error)

    console.log(error)
  }
}
