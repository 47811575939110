<div *ngIf="showCookieBanner">
  <div
    @fadeInOut
    class="cookie-layout bg-gray-250 border border-gray-500 rounded-1 w-full text-lg flex items-center text-gray-700 font-sans font-normal justify-between"
  >
    <p>
      We use
      <a
        aria-label="learn more about our privacy policy"
        class="cursor-pointer underline"
        [href]="privacyRoute"
        target="blank"
        >cookies</a
      >
      to improve your experience
    </p>

    <button
      (click)="acceptCookies()"
      class="cookie-button rounded-1 border font-normal outline-none ease-out duration-300 bg-transparent hover:bg-white hover:border-black border-gray-500"
    >
      Accept
    </button>
  </div>
</div>
