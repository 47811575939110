import { Injectable } from '@angular/core'
import { Store } from '@ngxs/store'
import { UserAccountState } from '@upkey-platform/accounts/user-data-access'

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private readonly NON_LOGGED_IN_USER_KEY = 'upkey'
  constructor(private store: Store) {}

  setItem(storageKey: string, keyValues: IKeyValuePair, userSpecific = true) {
    const localStorageKey = userSpecific
      ? `${storageKey.toLocaleUpperCase()}_${this.getUserEmail()}`
      : storageKey.toLocaleUpperCase()
    const storedValue = this.getItem(storageKey, userSpecific) || {}
    Object.keys(keyValues).forEach((key) => {
      storedValue[key] = keyValues[key]
    })

    localStorage.setItem(localStorageKey, JSON.stringify(storedValue))
  }

  getItem(storageKey: string, userSpecific = true) {
    const localStorageKey = userSpecific
      ? `${storageKey.toLocaleUpperCase()}_${this.getUserEmail()}`
      : storageKey.toLocaleUpperCase()
    const result = localStorage.getItem(localStorageKey)
    return result ? JSON.parse(result) : result
  }

  deleteItem(storageKey: string, userSpecific = true) {
    const localStorageKey = userSpecific
      ? `${storageKey.toLocaleUpperCase()}_${this.getUserEmail()}`
      : storageKey.toLocaleUpperCase()
    localStorage.removeItem(localStorageKey)
  }

  deleteItemKey(storageKey: string, key: string, userSpecific = true) {
    const localStorageKey = userSpecific
      ? `${storageKey.toLocaleUpperCase()}_${this.getUserEmail()}`
      : storageKey.toLocaleUpperCase()

    const storedValue = this.getItem(storageKey) || {}

    if (storedValue && Object.keys(storedValue).length && Object.keys(storedValue).includes(key)) {
      delete storedValue[key]
    }

    localStorage.setItem(localStorageKey, JSON.stringify(storedValue))
  }

  private getUserEmail() {
    return (
      this.store.selectSnapshot<string>(UserAccountState.userEmail) || this.NON_LOGGED_IN_USER_KEY
    )?.toUpperCase()
  }
}

interface IKeyValuePair {
  [type: string]: any
}
