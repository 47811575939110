import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { IResume } from '@upkey-platform/resumes/resumes-shared'
import { ResumeService } from '../resume.service'
import { GetResumeById } from './resume.actions'

@State<IResume>({
  name: 'Resume',
  defaults: {} as IResume
})
@Injectable()
export class ResumeState {
  constructor(private resumeService: ResumeService) {}

  @Selector()
  static type(state: IResume) {
    return state.type ?? null
  }

  @Selector()
  static fileName(state: IResume) {
    return state.fileName ?? ''
  }

  @Selector()
  static file(state: IResume) {
    return state.file ?? null
  }

  @Selector()
  static resume(state: IResume) {
    return state
  }

  @Action(GetResumeById)
  getResume(ctx: StateContext<IResume>, { payload }: GetResumeById) {
    return this.resumeService.getResumeById(payload).pipe(
      tap((res: IResume) => {
        ctx.patchState({
          ...res
        })
      })
    )
  }
}
