import { Injectable } from '@angular/core'
import { IResumeSucks, SocialMediaPlatform } from './your-resume-sucks.interface'
import { HttpService } from '@upkey-platform/core/core-data-access'
import { BehaviorSubject, Observable } from 'rxjs'
import { RESUME_SUCKS_MAPPER } from './you-resume-sucks-constant'
import { MY_RESUMES_API_URL } from '../my-resumes/my-resumes-api-routes'

@Injectable({
  providedIn: 'root'
})
export class YourResumeSucksService {
  private processResumeUploadSubject = new BehaviorSubject<boolean>(false)
  processResumeUpload$ = this.processResumeUploadSubject.asObservable()

  constructor(private http: HttpService) {}

  updateProcessingState(state = false) {
    this.processResumeUploadSubject.next(state)
  }

  shareResumeScore(resume: any, resumeURL: string, socialMedia: SocialMediaPlatform) {
    if (resume && socialMedia && Object.keys(resume).length) {
      let socialMediaURL = ''
      const resumeText = this.generateShareResumeText()
      const encodedResumeURL = encodeURIComponent(resumeURL)
      switch (socialMedia) {
        case 'facebook':
          socialMediaURL = `https://facebook.com/sharer.php?u=${encodedResumeURL}`
          break
        case 'whatsapp':
          socialMediaURL = `https://api.whatsapp.com/send?text=${resumeText}%0D%0A${encodedResumeURL}.`
          break
        case 'twitter':
          socialMediaURL = `https://twitter.com/intent/tweet?url=${encodedResumeURL}&text=${resumeText}&hashtags=Upkey`
          break
      }

      window.open(socialMediaURL, '_blank')
    }
  }

  shareByEmail(emails: string[], resumeId: string): Observable<any> {
    return this.http.post<any>(`${MY_RESUMES_API_URL.resume}/${resumeId}/share/email?context=MRS`, {
      emails
    })
  }

  private generateShareResumeText() {
    return `Hi, Check out my Resume Roast results!`
  }

  getResumeSucksResult(score: number): IResumeSucks {
    let result: IResumeSucks

    switch (true) {
      case score >= 90:
        result = RESUME_SUCKS_MAPPER[0]
        break
      case 65 <= score && score < 90:
        result = RESUME_SUCKS_MAPPER[1]
        break
      case 40 <= score && score < 65:
        result = RESUME_SUCKS_MAPPER[2]
        break
      case 21 <= score && score < 40:
        result = RESUME_SUCKS_MAPPER[3]
        break
      default:
        result = RESUME_SUCKS_MAPPER[4]
        break
    }

    return result
  }
}
