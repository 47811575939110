import { environment } from '@upkey-platform/core/core-shared'

export const CAREER_COURSE_API_URL = {
  checkQuiz: `${environment.apiUrl}/courses/quiz/check`,
  sbc: `${environment.apiUrl}/courses/sbc`,
  isCompleted: `${environment.apiUrl}/courses/is-completed`,
  getCertificate: `${environment.apiUrl}/courses/certificate`,
  getAllCourse: `${environment.apiUrl}/courses`,
  sendEmail: `${environment.apiUrl}/courses/email`,
  getCourseBySlug: (slug: string) => `${environment.apiUrl}/courses/${slug}`
}
