import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { ButtonShape, ButtonSize, ButtonType, ButtonVariant } from '../../data-type/button-type'

@Component({
  selector: 'u-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input() baseElement: 'button' | 'link' = 'button'
  @Input() variant: ButtonVariant = 'normal'
  @Input() size: ButtonSize = 'default'
  @Input() type: ButtonType = 'button'
  @Input() linkRole: 'link' | 'button' = 'link'
  @Input() shape: ButtonShape = 'curved'
  @Input() disabled = false
  @Input() loading = false
  @Input() color: string
  @Input() icon: string
  @Input() iconPosition: 'left' | 'right' = 'left'
  @Input() iconWidth = '10px'
  @Input() fontSize = this.iconWidth
  @Input() extraBtnClass = ''
  @Input() routerLink = ''
  @Input() isIconOnly = false
}
