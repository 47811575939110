export const LOCALSTORAGE_KEYS = {
  COURSE_LESSONS_STATUS: 'COURSE_LESSONS_STATUS',
  LAST_COURSE_VIEWED_INDEX: 'LAST_COURSE_VIEWED_INDEX',
  REDIRECT_ROUTE: 'REDIRECT_ROUTE',
  HAS_VISITED: 'HAS_VISITED',
  SELF_BRANDING_COURSE: 'SELF_BRANDING_COURSE',
  SELF_BRANDING_COURSE_STREAM_INFO: 'STREAM_INFO',
  SELF_BRANDING_BUTTON_CLICK_SOURCE: 'SBC_CLICK_SOURCE',
  JOB_POSTINGS_LOGO_PLACEHOLDER: 'JOB_POSTINGS_LOGO_PLACEHOLDER',
  LAST_COURSE_VIEW_NOTIFICATION: 'LAST_COURSE_VIEW_NOTIFICATION',
  ONBOARDING_REDIRECT_ROUTE: 'ONBOARDING_REDIRECT_ROUTE',
  FREE_RESUME_FIRST_VISIT: 'FR_FIRST_VISIT'
}
