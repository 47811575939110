import { MatDialogConfig } from '@angular/material/dialog'

export const FULLSCREEN_MODAL_CONFIG: MatDialogConfig = {
  autoFocus: false,
  maxWidth: '100vw',
  maxHeight: '100vh',
  height: '100%',
  width: '100%',
  panelClass: 'full-screen-modal'
}
