import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { SaveSettings, UserAccountState } from '@upkey-platform/accounts/user-data-access'
import { IUser } from '@upkey-platform/accounts/user-shared'
import { catchError, tap, throwError } from 'rxjs'
import { ProfileService } from '../profile.service'
import {
  ChangeCurrentStep,
  GetUserProfile,
  OnBoardDialogDisplayed,
  OverrideProfile,
  SetOnBoardByResume,
  SetOnBoardWizardDisplayed,
  UpdateProfile
} from './profile.actions'
import { ProfileStateModel, ProfileWizardSteps } from './profile.model'

@State<ProfileStateModel>({
  name: 'profile'
})
@Injectable()
export class ProfileState {
  constructor(private profileService: ProfileService, private store: Store) {}

  @Selector()
  static userProfile(state: ProfileStateModel) {
    return state.userProfile
  }

  @Selector()
  static isOnBoardDialogDisplayed(state: ProfileStateModel) {
    return state.isOnBoardDialogDisplayed
  }

  @Selector()
  static isOnBoardByResume(state: ProfileStateModel) {
    return state.isOnBoardByResume
  }

  @Selector()
  static onBoardWizardDisplayed(state: ProfileStateModel) {
    return state.onBoardWizardDisplayed
  }

  @Selector()
  static currentSectionNumber(state: ProfileStateModel) {
    return ProfileWizardSteps[state.currentStepIndex].sectionNumber
  }

  @Selector()
  static currentStepIndex(state: ProfileStateModel) {
    return state.currentStepIndex
  }

  @Selector()
  static isFinalStep(state: ProfileStateModel) {
    return state.currentStepIndex + 1 === ProfileWizardSteps.length
  }

  @Selector()
  static favoriteQuotes(state: ProfileStateModel) {
    return state.userProfile.favoriteQuotes
  }

  @Selector()
  static education(state: ProfileStateModel) {
    return state.userProfile.education
  }

  @Selector()
  static courses(state: ProfileStateModel) {
    return state.userProfile.courses
  }

  @Selector()
  static awards(state: ProfileStateModel) {
    return state.userProfile.awards
  }

  @Selector()
  static works(state: ProfileStateModel) {
    return state.userProfile.work
  }

  @Selector()
  static projects(state: ProfileStateModel) {
    return state.userProfile.projects
  }

  @Selector()
  static languages(state: ProfileStateModel) {
    return state.userProfile.languages
  }

  @Selector()
  static webLinks(state: ProfileStateModel) {
    return state.userProfile.webLinks
  }

  @Selector()
  static summary(state: ProfileStateModel) {
    return state.userProfile.summary
  }

  @Action(ChangeCurrentStep)
  changeCurrentStep(ctx: StateContext<ProfileStateModel>, { payload }: ChangeCurrentStep) {
    ctx.patchState({ currentStepIndex: payload })
  }

  @Action(GetUserProfile)
  getUserProfile(ctx: StateContext<ProfileStateModel>) {
    return this.profileService.getUser().pipe(
      tap((res: IUser) => {
        ctx.patchState({
          userProfile: res.profile,
          currentStepIndex: 0,
          isOnBoardDialogDisplayed: false,
          onBoardWizardDisplayed: false
        })
      }),
      catchError((err) => {
        return throwError(() => err)
      })
    )
  }

  @Action(UpdateProfile)
  updateProfile(ctx: StateContext<ProfileStateModel>, { payload }: UpdateProfile) {
    return this.profileService.updateProfile(payload.profile).pipe(
      tap(() => {
        const currentInfo = ctx.getState().userProfile
        if (payload.profile) {
          ctx.patchState({ userProfile: { ...currentInfo, ...payload.profile } })
        }
        if (payload.isOnBoard && !this.store.selectSnapshot<boolean>(UserAccountState.isOnboard)) {
          this.store.dispatch(
            new SaveSettings({
              isOnboard: true
            })
          )
        }
      }),
      catchError((err) => {
        return throwError(() => err)
      })
    )
  }

  @Action(OnBoardDialogDisplayed)
  onBoardDialogDisplayed(ctx: StateContext<ProfileStateModel>) {
    ctx.patchState({ isOnBoardDialogDisplayed: true })
  }

  @Action(SetOnBoardWizardDisplayed)
  setOnBoardWizardDisplayed(
    ctx: StateContext<ProfileStateModel>,
    { payload }: SetOnBoardWizardDisplayed
  ) {
    ctx.patchState({ onBoardWizardDisplayed: payload, currentStepIndex: 0 })
  }

  @Action(SetOnBoardByResume)
  setOnBoardByResume(ctx: StateContext<ProfileStateModel>, { payload }: SetOnBoardByResume) {
    ctx.patchState({ isOnBoardByResume: payload })
  }

  @Action(OverrideProfile)
  overrideProfile(ctx: StateContext<ProfileStateModel>) {
    return this.profileService.overrideProfile().pipe(
      tap((res) => {
        if (res) {
          ctx.patchState({ userProfile: { ...res }, isOnBoardDialogDisplayed: false })
        }
      })
    )
  }
}
