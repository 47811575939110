import { Injectable } from '@angular/core'
import {
  CertificateResponse,
  ICourse,
  ICourseCertificate,
  ICourseEmail,
  IQuizAnswerRequest,
  IQuizAnswerResponse
} from '@upkey-platform/courses/courses-shared'
import { Observable } from 'rxjs'
import { HttpService, LOCALSTORAGE_KEYS } from '@upkey-platform/core/core-data-access'
import { CAREER_COURSE_API_URL } from './career-course-api-routes'
import { ICourseLocalStorage, ILessonsStatus } from './career-course.interface'
import { LocalStorageService } from '@upkey-platform/accounts/user-frontend'

@Injectable({
  providedIn: 'root'
})
export class CareerCourseService {
  constructor(private http: HttpService, private localStorage: LocalStorageService) {}

  checkQuiz(payload: IQuizAnswerRequest): Observable<IQuizAnswerResponse> {
    return this.http.post<IQuizAnswerResponse>(CAREER_COURSE_API_URL.checkQuiz, payload)
  }

  isCourseCompleted(courseId: string): Observable<CertificateResponse> {
    return this.http.get<CertificateResponse>(`${CAREER_COURSE_API_URL.isCompleted}/${courseId}`)
  }

  getCertificate(s3Key: string): Observable<ICourseCertificate> {
    return this.http.get<ICourseCertificate>(`${CAREER_COURSE_API_URL.getCertificate}/${s3Key}`)
  }

  getAllCourses(): Observable<ICourse[]> {
    return this.http.get<ICourse[]>(CAREER_COURSE_API_URL.getAllCourse)
  }

  getCourse(courseSlug: string): Observable<ICourse> {
    return this.http.get<ICourse>(CAREER_COURSE_API_URL.getCourseBySlug(courseSlug))
  }

  sendCourseUrlByEmail(payload: ICourseEmail): Observable<void> {
    return this.http.post(CAREER_COURSE_API_URL.sendEmail, payload)
  }

  localStorageInitiate(courseId: string, numbertOfLessons: number) {
    const allCoursesStatus = this.localStorageGetAllCourses()

    const course = allCoursesStatus?.find((c) => c.courseId === courseId)
    if (!course) {
      allCoursesStatus.push({
        courseId,
        lessonsStatus: new Array(numbertOfLessons).fill(null)
      })
      this.localStorage.setItem(LOCALSTORAGE_KEYS.COURSE_LESSONS_STATUS, {
        allCoursesStatus: JSON.stringify(allCoursesStatus)
      })
    }
  }

  localStorageSetStatus(courseId: string, lessonIndex: number, status: ILessonsStatus) {
    const allCoursesStatus = this.localStorageGetAllCourses()
    let courseIndex = allCoursesStatus?.findIndex((c) => c.courseId === courseId)
    courseIndex = courseIndex > -1 ? courseIndex : allCoursesStatus?.length

    const lessonsStatus = (allCoursesStatus && allCoursesStatus[courseIndex]?.lessonsStatus) || []
    lessonsStatus[lessonIndex] = status
    allCoursesStatus[courseIndex] = {
      courseId,
      lessonsStatus
    }

    this.localStorage.setItem(LOCALSTORAGE_KEYS.COURSE_LESSONS_STATUS, {
      allCoursesStatus: JSON.stringify(allCoursesStatus)
    })
  }

  localStorageGetLessonsStatus(courseId: string): ILessonsStatus[] {
    const allCoursesStatus = this.localStorageGetAllCourses()
    return allCoursesStatus?.find((c) => c.courseId === courseId)?.lessonsStatus ?? []
  }

  localStorageGetAllCourses(): ICourseLocalStorage[] {
    const savedStatuses: string = this.localStorage.getItem(
      LOCALSTORAGE_KEYS.COURSE_LESSONS_STATUS
    )?.allCoursesStatus

    if (savedStatuses) {
      return JSON.parse(savedStatuses)
    }
    return []
  }
}
