import { Component } from '@angular/core'
import { Select } from '@ngxs/store'
import { IUser } from '@upkey-platform/accounts/user-shared'
import { Observable } from 'rxjs'
import { UserAccountState } from '@upkey-platform/accounts/user-data-access'

@Component({
  selector: 'u-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss']
})
export class PublicLayoutComponent {
  @Select(UserAccountState.currentUser) currentUser$: Observable<IUser>
}
