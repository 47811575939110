import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core'
import { IS_PLATFORM_SERVER } from '@upkey-platform/core/core-data-access'
import { environment } from '@upkey-platform/core/core-shared'
@Component({
  selector: 'u-youtube-player',
  templateUrl: './youtube-player.component.html'
})
export class YoutubePlayerComponent implements OnInit {
  @Input() videoId: string
  @Input() height!: number
  @Input() width!: number
  @Input() playerConfig = {
    controls: 1,
    mute: 0,
    autoplay: 1,
    origin: environment.websiteUrl
  }
  @Output() videoPlayer = new EventEmitter()
  private scriptTagID = 'YOUTUBE_IFRAME_API_SCRIPT_TAG'

  constructor(@Inject(IS_PLATFORM_SERVER) private isServer: boolean) {}
  ngOnInit(): void {
    // Prevent this script being added on every onInit lifecycle
    if (!this.isServer) {
      const $youtubeAPIScriptTag = document.getElementById(this.scriptTagID)
      if (!$youtubeAPIScriptTag) {
        const tag = document.createElement('script')
        tag.src = 'https://www.youtube.com/iframe_api'
        tag.id = this.scriptTagID
        document.body.appendChild(tag)
      }
    }
  }

  savePlayer(player: YT.PlayerEvent) {
    this.videoPlayer.emit(player)
  }
}
