import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { CareerCourseStateModel } from './career-course.model'
import { CareerCourseService } from '../career-course.service'
import {
  GetCourse,
  CheckQuiz,
  IsCompleted,
  ChangeLessonStatus,
  SetCurrentLesson,
  SetFirstTimePlayVideo,
  ResetCurrentLesson
} from './career-course.actions'
import {
  ICourse,
  IQuizAnswerResponse,
  CertificateResponse
} from '@upkey-platform/courses/courses-shared'
import { flatten } from 'lodash'
import { ILessonSlugInfo } from '../career-course.interface'

@State<CareerCourseStateModel>({
  name: 'CareerCourse'
})
@Injectable()
export class CareerCourseState {
  constructor(private careerCourseService: CareerCourseService) {}

  @Selector()
  static isCompleted(state: CareerCourseStateModel) {
    return state.completed ?? false
  }

  @Selector()
  static title(state: CareerCourseStateModel) {
    return state.course?.title ?? ''
  }

  @Selector()
  static lessonsStatus(state: CareerCourseStateModel) {
    return state.lessonsStatus
  }

  @Selector()
  static lessons(state: CareerCourseStateModel) {
    return state.course?.fullLessons ?? []
  }

  @Selector()
  static categoriesInfo(state: CareerCourseStateModel) {
    return state.course?.categoriesInfo ?? []
  }

  @Selector()
  static currentlessonInfo(state: CareerCourseStateModel) {
    return state.currentLessonInfo
  }

  @Selector()
  static questions(state: CareerCourseStateModel) {
    return state.course?.quiz ?? []
  }

  @Selector()
  static numberOfLessons(state: CareerCourseStateModel) {
    return state.course?.fullLessons?.length ?? 0
  }

  @Selector()
  static numberOfQuestions(state: CareerCourseStateModel) {
    return state.course?.quiz?.length ?? 0
  }

  @Selector()
  static courseId(state: CareerCourseStateModel) {
    return state.course?.id
  }

  @Selector()
  static courseSlug(state: CareerCourseStateModel) {
    return state.course?.slug
  }

  @Selector()
  static certificate(state: CareerCourseStateModel) {
    return state.certificate
  }

  @Selector()
  static firstTimePlayVideo(state: CareerCourseStateModel) {
    return state.firstTimePlayVideo
  }

  @Action(GetCourse)
  getCourse(ctx: StateContext<CareerCourseStateModel>, { payload }: GetCourse) {
    return this.careerCourseService.getCourse(payload).pipe(
      tap((res: ICourse) => {
        let index = -1
        const categoriesInfo = res.lessons.map((el) => ({
          title: el.title,
          duration: el.lessons.map((l) => l.video.duration).reduce((sum, x) => sum + x, 0),
          numberOfVideos: el.lessons.length,
          indexedLessons: el.lessons.map((lesson) => {
            index += 1
            return { lesson, index }
          })
        }))
        const fullLessons = flatten(
          res.lessons.map((el) => el.lessons.map((c) => ({ ...c, catergory: el.title })))
        )
        this.careerCourseService.localStorageInitiate(res.id, fullLessons?.length ?? 0)
        ctx.patchState({
          firstTimePlayVideo: false,
          course: {
            ...res,
            fullLessons,
            categoriesInfo
          },
          lessonsStatus: this.careerCourseService.localStorageGetLessonsStatus(res.id)
        })
      }),
      catchError((err) => {
        return throwError(() => err)
      })
    )
  }

  @Action(SetCurrentLesson)
  setCurrentLesson(ctx: StateContext<CareerCourseStateModel>, { payload }: SetCurrentLesson) {
    const state = ctx.getState()
    const lessonIndex = payload.index
    const slug = state.course.slug
    const lessonInfo: ILessonSlugInfo = {}
    lessonInfo[slug] = {
      index: lessonIndex,
      lesson: state.course.fullLessons[lessonIndex],
      status: state.lessonsStatus[lessonIndex],
      autoPlay: payload.autoPlay
    }
    ctx.patchState({
      currentLessonInfo: {
        ...state.currentLessonInfo,
        ...lessonInfo
      }
    })
  }

  @Action(ResetCurrentLesson)
  resetCurrentLesson(ctx: StateContext<CareerCourseStateModel>) {
    const state = ctx.getState()
    const slug = state.course.slug
    const currentLesson: any = {}
    currentLesson[slug] = undefined

    const currentLessonInfo = {
      ...state.currentLessonInfo,
      ...currentLesson
    }
    ctx.patchState({
      currentLessonInfo
    })
  }

  @Action(CheckQuiz)
  checkQuiz(ctx: StateContext<CareerCourseStateModel>, { payload }: CheckQuiz) {
    return this.careerCourseService.checkQuiz(payload).pipe(
      tap((res: IQuizAnswerResponse) => {
        if (res.success) {
          ctx.patchState({ completed: true, certificate: res.certificate })
        }
      }),
      catchError((err) => {
        return throwError(() => err)
      })
    )
  }

  @Action(IsCompleted)
  isCompleted(ctx: StateContext<CareerCourseStateModel>, { payload }: IsCompleted) {
    return this.careerCourseService.isCourseCompleted(payload).pipe(
      tap((res: CertificateResponse) => {
        if (res) {
          ctx.patchState({ certificate: res, completed: true })
        } else {
          ctx.patchState({ certificate: undefined, completed: false })
        }
      }),
      catchError((err) => {
        return throwError(() => err)
      })
    )
  }

  @Action(ChangeLessonStatus)
  changeLessonStatus(ctx: StateContext<CareerCourseStateModel>, { payload }: ChangeLessonStatus) {
    this.careerCourseService.localStorageSetStatus(payload.courseId, payload.lessonIndex, payload.status)
    ctx.patchState({
      lessonsStatus: this.careerCourseService.localStorageGetLessonsStatus(payload.courseId)
    })
  }

  @Action(SetFirstTimePlayVideo)
  setFirstTimePlayVideo(ctx: StateContext<CareerCourseStateModel>) {
    ctx.patchState({
      firstTimePlayVideo: true
    })
  }
}
