import { Inject, Injectable } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { CookieConsentConfig } from '@upkey-platform/core/core-shared'

@Injectable({
  providedIn: 'root'
})
export class CookieConsentService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  getCookie(name: string) {
    const value = '; ' + document.cookie
    const parts = value.split('; ' + name + '=')
    return parts.length < 2 ? undefined : parts.pop()?.split(';')?.shift()
  }

  getCookieValue(name: string) {
    const cookieValue = this.document.cookie.match(RegExp('(?:^|;\\s*)' + name + '=([^;]*)'))
    return cookieValue ? cookieValue[1] : null
  }

  setCookie(config: CookieConsentConfig) {
    const { expiryDays, domain, path, secure, value, name } = config
    const exdate = new Date()
    exdate.setHours(exdate.getHours() + (expiryDays || 365) * 24)

    const cookie = [name + '=' + value, 'expires=' + exdate.toUTCString(), 'path=' + (path || '/')]

    if (domain) {
      cookie.push('domain=' + domain)
    }
    if (secure) {
      cookie.push('secure')
    }
    this.document.cookie = cookie.join(';')
  }
}
