import { environment } from '@upkey-platform/core/core-shared'

export const USERS_API_URL = {
  register: `${environment.apiUrl}/auth/register`,
  getProviderUrl: (provider: string) => `${environment.apiUrl}/auth/${provider}/redirect`,
  getValidateReferralCodeUrl: (code: string) => `${environment.apiUrl}/auth/referral/${code}`,
  login: `${environment.apiUrl}/auth/login`,
  logout: `${environment.apiUrl}/auth/logout`,
  me: `${environment.apiUrl}/auth/me`,

  resendEmailVerificationLink: `${environment.apiUrl}/auth/email/resend`,
  verifyEmail: `${environment.apiUrl}/auth/email/verify`,

  forgotPassword: `${environment.apiUrl}/auth/password/forgot`,
  resetPassword: `${environment.apiUrl}/auth/password/reset`,

  saveSettings: `${environment.apiUrl}/users/update-user`,
  updateNotificationsSettings: `${environment.apiUrl}/users/update-notifications-settings`,
  changePassword: `${environment.apiUrl}/users/change-password`,
  deleteAccount: `${environment.apiUrl}/users/delete-account`,
  checkUsername: `${environment.apiUrl}/users/check-username`,
  updateAvatar: `${environment.apiUrl}/users/update-avatar`
}
