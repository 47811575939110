export class SkillsHelper {
  static getAllSkills(ordered = true) {
    if (ordered) {
      return skills.sort((a, b) => a.localeCompare(b))
    } else {
      return skills
    }
  }
}

const skills = ['Karate', 'Programing', 'Typing', 'Gymnastics', 'Art']
