export enum SBCStatusType {
  LIVE = 'Live',
  COMING_SOON = 'Coming-soon',
  AVAILABLE = 'Available'
}

export enum SBCButtonSource {
  ENROLL = 'enroll',
  SAVE = 'saveDate'
}
