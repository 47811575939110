import { upkeyPlatformAppleIcon } from './apple';
import { upkeyPlatformDiscordIcon } from './discord';
import { upkeyPlatformFacebookFIcon } from './facebook-f';
import { upkeyPlatformGithubIcon } from './github';
import { upkeyPlatformInstagramAltIcon } from './instagram-alt';
import { upkeyPlatformLinkedinAltIcon } from './linkedin-alt';
import { upkeyPlatformLinkedinIcon } from './linkedin';
import { upkeyPlatformTictokIcon } from './tictok';
import { upkeyPlatformTwitterIcon } from './twitter';
import { upkeyPlatformWhatsappIcon } from './whatsapp';
export const socialIcons = [upkeyPlatformAppleIcon, upkeyPlatformDiscordIcon, upkeyPlatformFacebookFIcon, upkeyPlatformGithubIcon, upkeyPlatformInstagramAltIcon, upkeyPlatformLinkedinAltIcon, upkeyPlatformLinkedinIcon, upkeyPlatformTictokIcon, upkeyPlatformTwitterIcon, upkeyPlatformWhatsappIcon];
