<div class="w-full">
  <mat-tab-group
    [class]="tabGroupClass"
    (selectedIndexChange)="setSelectedIndex($event)"
    [selectedIndex]="selectedIndex"
    [disableRipple]="disableRipple"
  >
    <mat-tab
      *ngFor="let item of tabItems; let i = index"
      [labelClass]="i === selectedIndex ? [selectedTabClass, customTabClass] : customTabClass"
    >
      <ng-template mat-tab-label>
        <span>{{ item.label }}</span>
        <ng-container *ngIf="item.icon">
          <svg-icon [key]="item.icon"></svg-icon>
        </ng-container>
        <div *ngIf="item.badgeCount !== undefined" class="mat-badge">{{ item.badgeCount }}</div>
      </ng-template>
      <ng-container *ngTemplateOutlet="tabGroupTemplate"></ng-container>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #tabGroupTemplate>
  <ng-container *ngIf="tabGroupTemplateContent?.templateRef">
    <ng-container
      [ngTemplateOutlet]="tabGroupTemplateContent.templateRef"
      [ngTemplateOutletContext]="{
        $implicit: { selectedIndex: this.selectedIndex }
      }"
    ></ng-container>
  </ng-container>
</ng-template>
