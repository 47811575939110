import { LanguagesHelper } from '@upkey-platform/utils/utils-shared'
import { IMatchingEntry, IJobMappedMatching } from '@upkey-platform/sovren/sovren-shared'

export function fixEntriesTitle(item: IMatchingEntry, section: IJobMappedMatching) {
  switch (section.type) {
    case 'Skills':
      return {
        tags: section.fullTags.find((el) => el.type === item.type)?.tags,
        type: item.type,
        text:
          item.type === 'Positive'
            ? 'The job mentions the following skills that the candidate has experience with:'
            : item.type === 'Mixed'
            ? 'The candidate has experience with the following skills, that experience is not current and is therefore less valuable:'
            : 'The job does not mention the following skills that the candidate has experience with:'
      }

    case 'JobTitles':
      return {
        tags: section.fullTags.find((el) => el.type === item.type)?.tags,
        type: item.type,
        text:
          item.type === 'Positive'
            ? 'The job mentions the following candidate’s previous roles:'
            : item.type === 'Mixed'
            ? 'The candidate has the following work experiences, that is not current and is therefore less valuable:'
            : 'The job does not mention the following work experiences that the candidate has:'
      }

    case 'Languages': {
      const tags = section.fullTags
        .find((el) => el.type === item.type)
        ?.tags?.map((tag) => {
          return LanguagesHelper.getLanguageByAbbreviation(tag)?.name || tag
        })
        .join(', ')
      return {
        type: item.type,
        text:
          item.type === 'Positive' ? 'At least some proficiency in ' + tags : 'No proficiency in ' + tags
      }
    }
    default:
      return item
  }
}
