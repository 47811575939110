import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { YesNoPipe } from './yes-no.pipe'
import { SentenceCasePipe } from './sentence-case.pipe'
import { GetByPathPipe } from './get-by-path.pipe'
import { DownloadLinkPipe } from './download-link.pipe'
import { S3FileLinkPipe } from './s3-file-link.pipe'
import { FileSizePipe } from './file-size.pipe'
import { FileIconPipe } from './file-icon.pipe'
import { TimeAgoPipe } from './time-ago.pipe'
import { RangePipe } from './range.pipe'
import { GetEnumKeyPipe } from './get-enum-key.pipe'
import { TrimTextPipe } from './trim-text.pipe'

@NgModule({
  declarations: [
    YesNoPipe,
    GetByPathPipe,
    SentenceCasePipe,
    DownloadLinkPipe,
    S3FileLinkPipe,
    FileSizePipe,
    FileIconPipe,
    TimeAgoPipe,
    RangePipe,
    GetEnumKeyPipe,
    TrimTextPipe
  ],
  imports: [CommonModule],
  exports: [
    YesNoPipe,
    GetByPathPipe,
    SentenceCasePipe,
    DownloadLinkPipe,
    S3FileLinkPipe,
    FileSizePipe,
    FileIconPipe,
    TimeAgoPipe,
    RangePipe,
    GetEnumKeyPipe,
    TrimTextPipe
  ]
})
export class PipesModule {}
