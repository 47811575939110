import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IfPlatformDirective } from './If-platform.directive'
import { TrackDirective } from './track.directive'
import { ExternalLinksDirective } from './external-links.directive'
import { LazyLoadImageDirective } from './lazy-load-image.directive'
import { ClickStopPropagationDirective } from './click-stop-propagation.directive'
import { MediaMatchDirective } from './media-match.directive'
import { ScrollIntoViewDirective } from './scroll-into-view.directive'
import { TabGroupTemplateDirective } from './tab-group-template.directive'
import { ScrollToDirective } from './scroll-to.directive'

/**
 * Directive Module to share all directives
 */
@NgModule({
  declarations: [
    IfPlatformDirective,
    TrackDirective,
    ExternalLinksDirective,
    LazyLoadImageDirective,
    ClickStopPropagationDirective,
    MediaMatchDirective,
    ScrollIntoViewDirective,
    TabGroupTemplateDirective,
    ScrollToDirective
  ],
  imports: [CommonModule],
  exports: [
    IfPlatformDirective,
    TrackDirective,
    ExternalLinksDirective,
    LazyLoadImageDirective,
    ClickStopPropagationDirective,
    MediaMatchDirective,
    ScrollIntoViewDirective,
    TabGroupTemplateDirective,
    ScrollToDirective
  ]
})
export class DirectivesModule {}
