<ng-container *ngIf="baseElement === 'button'; else linkBaseTemplate">
  <button
    [attr.type]="type"
    [disabled]="disabled || loading"
    [ngClass]="{
      'btn-normal': variant === 'normal',
      'btn-plain': variant === 'plain',
      'btn-ghost': variant === 'ghost',
      'btn-link': variant === 'link',
      'btn-white': variant === 'white',
      'btn-gray': variant === 'gray',
      'btn-black': variant === 'black',
      'btn-black-invert': variant === 'black-invert',
      'btn-blue': variant === 'blue',
      'btn-white-outline': variant === 'white-outline',
      'btn-ghost-outline': variant === 'ghost-outline',
      'btn-delete': variant === 'delete',
      'btn-basic': variant === 'basic',
      'btn-secondary': variant === 'secondary',
      'btn-small': size === 'small',
      'btn-medium': size === 'medium',
      'btn-medium-sm': size === 'medium-sm',
      'btn-medium-base': size === 'medium-base',
      'btn-medium-wide': size === 'medium-wide',
      'btn-micro': size === 'micro',
      'btn-default': size === 'default',
      'btn-disabled': this.disabled || this.loading || variant === 'disable',
      'btn-curved': shape === 'curved',
      'btn-pill': shape === 'pill',
      'btn-block': shape === 'block',
      'btn-inherit-border-radius': shape === 'inherit'
    }"
    class="btn-base {{ extraBtnClass }}"
    [ngStyle]="{ color }"
  >
    <div
      class="flex justify-center items-center"
      [ngClass]="{ 'flex-row-reverse': iconPosition === 'right' }"
    >
      <div
        *ngIf="loading"
        class="animate-spin rounded-full h-2 w-2 border-b-2 border-gray-900"
        [ngClass]="{ 'ml-2': iconPosition === 'right', 'mr-2': iconPosition === 'left' }"
      ></div>
      <div
        class="flex flex-wrap items-center"
        [ngClass]="{
          'space-x-1': !isIconOnly,
          'flex-row-reverse space-x-reverse': iconPosition === 'right'
        }"
      >
        <svg-icon
          *ngIf="icon && !loading"
          [key]="icon"
          [width]="iconWidth"
          [fontSize]="fontSize"
        ></svg-icon>
        <span class="flex flex-wrap button-content">
          <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        </span>
      </div>
    </div>
  </button>
</ng-container>

<ng-template #linkBaseTemplate>
  <a
    [attr.role]="linkRole"
    [ngClass]="{
      'btn-normal': variant === 'normal',
      'btn-plain': variant === 'plain',
      'btn-ghost': variant === 'ghost',
      'btn-link': variant === 'link',
      'btn-white': variant === 'white',
      'btn-gray': variant === 'gray',
      'btn-black': variant === 'black',
      'btn-blue': variant === 'blue',
      'btn-white-outline': variant === 'white-outline',
      'btn-ghost-outline': variant === 'ghost-outline',
      'btn-delete': variant === 'delete',
      'btn-basic': variant === 'basic',
      'btn-secondary': variant === 'secondary',
      'btn-small': size === 'small',
      'btn-medium': size === 'medium',
      'btn-medium-sm': size === 'medium-sm',
      'btn-medium-base': size === 'medium-base',
      'btn-medium-wide': size === 'medium-wide',
      'btn-micro': size === 'micro',
      'btn-default': size === 'default',
      'btn-disabled': this.disabled || this.loading,
      'btn-curved': shape === 'curved',
      'btn-pill': shape === 'pill',
      'btn-block': shape === 'block',
      'btn-inherit-border-radius': shape === 'inherit'
    }"
    class="btn-base {{ extraBtnClass }}"
    [ngStyle]="{ color }"
    [routerLink]="routerLink"
  >
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </a>
</ng-template>

<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>
