import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ButtonComponent } from './components/button/button.component'
import { LogoComponent } from './components/branding/logo/logo.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HeadlineComponent } from './components/headline/headline.component'
import { FormWrapperComponent } from './components/form-wrapper/form-wrapper.component'
import { SvgIconsModule } from '@ngneat/svg-icon'
import { commonIcons } from '@upkey-platform/icons'
import { LoaderComponent } from './components/loader/loader.component'
import { InputComponent } from './components/input/input.component'
import { VideoPlayerComponent } from './components/video-player/video-player.component'
import { ShareButtonsComponent } from './components/share-buttons/share-buttons.component'
import { ShareModule } from 'ngx-sharebuttons'
import { ToastNotificationComponent } from './components/toast-notification/toast-notification.component'
import { CircularProgressComponent } from './components/circular-progress/circular-progress.component'
import { TagInputModule } from 'ngx-chips'
import { InputChipComponent } from './components/input-chip/input-chip.component'
import { InteractableCardComponent } from './components/interactable-card/interactable-card.component'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatNativeDateModule } from '@angular/material/core'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { YouTubePlayerModule } from '@angular/youtube-player'
import { YoutubePlayerComponent } from './components/youtube-player/youtube-player.component'
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component'
import { MatDialogModule } from '@angular/material/dialog'
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component'
import { SpinnerComponent } from './components/spinner/spinner.component'
import { PaginationComponent } from './components/pagination/pagination.component'
import { NgxPaginationModule } from 'ngx-pagination'
import { DirectivesModule, PipesModule } from '@upkey-platform/utils/utils-frontend'
import { DirectivesModule as CoreFrontEndDirectivesModule } from '@upkey-platform/core/core-frontend'
import { FoldableTextComponent } from './components/foldable-text/foldable-text.component'
import { CardComponent } from './components/card/card.component'
import { CloseButtonComponent } from './components/close-button/close-button.component'
import { DocViewerComponent } from './components/doc-viewer/doc-viewer.component'
import { FileDragDropComponent } from './components/file-drag-drop/file-drag-drop.component'
import { NgxFileDropModule } from 'ngx-file-drop'
import { NgxDocViewerModule } from 'ngx-doc-viewer'
import { MobileActionModalComponent } from './components/mobile-action-modal/mobile-action-modal.component'
import { NavbarComponent } from './components/navbar/navbar.component'
import { HeadroomModule } from '@ctrl/ngx-headroom'
import { SectionWidgetComponent } from './components/section-widget/section-widget.component'
import { CookieBannerComponent } from './components/cookie-banner/cookie-banner.component'
import { RouterModule } from '@angular/router'
import player from 'lottie-web/build/player/lottie_svg'
import { LottieModule, LottieCacheModule } from 'ngx-lottie'
import { LottieAnimationComponent } from './components/lottie-animation/lottie-animation.component'
import { ProgressIndicatorComponent } from './components/progress-indicator/progress-indicator.component'
import { WysiwygWidgetComponent } from './components/wysiwyg-widget/wysiwyg-widget.component'
import { NgxEditorModule } from 'ngx-editor'
import { YoutubePlayerModalComponent } from './components/youtube-player-modal/youtube-player-modal.component'
import { MatExpansionModule } from '@angular/material/expansion'
import { CountDownTimerComponent } from './components/count-down-timer/count-down-timer.component'
import { FaqComponent } from './components/faq/faq.component'
import { WelcomeNotificationComponent } from './components/welcome-notification/welcome-notification.component'
import { TabGroupComponent } from './components/tab-group/tab-group.component'
import { MatTabsModule } from '@angular/material/tabs'
import { AvatarComponent } from './components/avatar/avatar.component'
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component'
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component'

export function playerFactory() {
  return player
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SvgIconsModule.forRoot({ icons: commonIcons }),
    ShareModule,
    TagInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    YouTubePlayerModule,
    MatDialogModule,
    NgxPaginationModule,
    DirectivesModule,
    CoreFrontEndDirectivesModule,
    NgxFileDropModule,
    NgxDocViewerModule,
    HeadroomModule,
    NgxEditorModule,
    MatDialogModule,
    MatExpansionModule,
    MatTabsModule,
    MatAutocompleteModule,
    PipesModule,
    MatProgressSpinnerModule,
    LottieModule.forRoot({ player: playerFactory }),
    LottieCacheModule.forRoot()
  ],
  declarations: [
    CloseButtonComponent,
    ToastNotificationComponent,
    VideoPlayerComponent,
    ButtonComponent,
    LogoComponent,
    InputComponent,
    HeadlineComponent,
    FormWrapperComponent,
    LoaderComponent,
    ShareButtonsComponent,
    CircularProgressComponent,
    InputChipComponent,
    InteractableCardComponent,
    YoutubePlayerComponent,
    ConfirmationDialogComponent,
    ProgressBarComponent,
    SpinnerComponent,
    PaginationComponent,
    FoldableTextComponent,
    CardComponent,
    DocViewerComponent,
    FileDragDropComponent,
    MobileActionModalComponent,
    NavbarComponent,
    SectionWidgetComponent,
    CookieBannerComponent,
    LottieAnimationComponent,
    ProgressIndicatorComponent,
    WysiwygWidgetComponent,
    YoutubePlayerModalComponent,
    CountDownTimerComponent,
    FaqComponent,
    WelcomeNotificationComponent,
    TabGroupComponent,
    AvatarComponent,
    AutocompleteComponent,
    SkeletonLoaderComponent
  ],
  exports: [
    CloseButtonComponent,
    VideoPlayerComponent,
    ButtonComponent,
    LogoComponent,
    InputComponent,
    HeadlineComponent,
    FormWrapperComponent,
    LoaderComponent,
    ShareButtonsComponent,
    CircularProgressComponent,
    InputChipComponent,
    InteractableCardComponent,
    YoutubePlayerComponent,
    ProgressBarComponent,
    SpinnerComponent,
    PaginationComponent,
    FoldableTextComponent,
    CardComponent,
    DocViewerComponent,
    FileDragDropComponent,
    MobileActionModalComponent,
    NavbarComponent,
    SectionWidgetComponent,
    CookieBannerComponent,
    LottieAnimationComponent,
    ProgressIndicatorComponent,
    WysiwygWidgetComponent,
    YoutubePlayerModalComponent,
    CountDownTimerComponent,
    FaqComponent,
    WelcomeNotificationComponent,
    TabGroupComponent,
    AvatarComponent,
    AutocompleteComponent,
    SkeletonLoaderComponent
  ]
})
export class UiModule {}
