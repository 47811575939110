export const environment = {
  production: true,
  apiUrl: 'https://upkey.com/api',
  baseApiUrl: `/api`,
  websiteUrl: 'https://upkey.com',
  aiWebsiteUrl: 'https://upkey.ai',
  aiApiUrl: 'https://upkey.ai/api',
  sentry: {
    environment: 'production',
    dsn: 'https://d0e4a940fb7242098a0707977137bfcc@o467736.ingest.sentry.io/5632692'
  },
  mixpanel: {
    token: '454abe8733cfb0baf790d3c1e5da9cb0',
    proxyServer: 'https://upkey.com/mixpanel'
  },
  facebookPixelId: '202835097283715',
  googleAnalyticsToken: 'UA-131041654-1',
  googleAnalyticsTokenAi: 'G-HTR3HVRGDC',
  domain: 'upkey.com',
  s3Bucket: 'https://upkey-platform.s3.us-east-1.amazonaws.com',
  extensionId: 'jecklmmnoefoaddnilmlmppkegfmfdfm',
  jobPostingId_NM_Indy: '2abe69fa-144b-4736-833e-d92107b23941',
  magicBoxes: {
    cbrands: 'f5d3f949-5fee-40e4-ba45-7e8b76f47b34',
    nmTc: 'f9683915-51d5-4ff1-b633-9d971bdbe74a',
    nmIndy: '100523ca-c21d-4fd4-9f31-7aba74241cc3'
  },
  canSaveToTemplate: false
}
