import { Component, Input } from '@angular/core'

@Component({
  selector: 'u-headline',
  templateUrl: './headline.component.html',
  styleUrls: ['./headline.component.scss']
})
export class HeadlineComponent {
  @Input() heading = 'h1'
  @Input() weight: string
  @Input() color: string
  @Input() colorClass = 'text-gray-800 font-sans'
  @Input() extraClass: string
}
