import { IJob } from '@upkey-platform/jobs/jobs-shared'
import { IResume } from '@upkey-platform/resumes/resumes-shared'
import { IPaginationMeta } from 'nestjs-typeorm-paginate'
import { IJobMatching } from '@upkey-platform/sovren/sovren-shared'

export interface IJobScoreWizardStep {
  sectionNumber: number
  page: 'SelectResume' | 'JobLink' | 'JobDetails' | 'JobScore'
}

export const JobScoreWizardSteps: IJobScoreWizardStep[] = [
  { sectionNumber: 0, page: 'SelectResume' },
  { sectionNumber: 0, page: 'JobLink' },
  { sectionNumber: 1, page: 'JobDetails' },
  { sectionNumber: 2, page: 'JobScore' }
]

export interface MyJobsStateModel {
  jobs: IJob[]
  jobScore: IJobMatching
  resume: IResume
  jobLink: string
  job: IJob
  currentStepIndex: number
  paginationMeta: Partial<IPaginationMeta>
}
