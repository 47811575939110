import { Component, Inject, Input } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store } from '@ngxs/store'
import { Logout } from '@upkey-platform/accounts/user-data-access'
import { IUser } from '@upkey-platform/accounts/user-shared'
import { IS_PLATFORM_SERVER } from '@upkey-platform/core/core-data-access'
import { UserAccountPaths } from '@upkey-platform/accounts/user-frontend'
import { fromEvent, Observable, throttleTime } from 'rxjs'
import { ApplicationRoutes } from '../../../../app-routes-names'
import { PublicPaths } from '../../../../features/public/public-route-names'

@UntilDestroy()
@Component({
  selector: 'u-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() currentUser$: Observable<IUser>
  showMenu = false
  loginRoute = UserAccountPaths.LoginPath
  signupRoute = UserAccountPaths.SignupPath
  jobsRoute = PublicPaths.JobsPagePath
  dashboardRoute = `/${ApplicationRoutes.Profile}`
  careerCreationCourseLandingRoute = PublicPaths.CCCPagePath
  selfBrandingCourseLandingRoute = PublicPaths.SelfBrandingCoursePath
  scrollPosition: string | number
  freeResumeReviewRoute = `/${ApplicationRoutes.FreeResume}`

  constructor(
    private store: Store,
    private router: Router,
    public dialog: MatDialog,
    @Inject(IS_PLATFORM_SERVER) private isServer: boolean
  ) {
    if (!this.isServer) {
      fromEvent(window, 'scroll')
        .pipe(untilDestroyed(this), throttleTime(300))

        .subscribe(() => {
          this.scrollPosition = window.pageYOffset
        })
    }
  }

  toggleNavbar() {
    this.showMenu = !this.showMenu
  }

  logOut() {
    this.store.dispatch(new Logout())
  }

  closeMenu() {
    this.showMenu = false
  }

  goToCareer() {
    if (!this.isServer) {
      // TODO: add navigation triggering in a router subscription
      const doc = document.getElementById('career-tools')
      if (doc) {
        doc.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        })
      } else {
        this.router.navigateByUrl('/')
      }
    }
  }

  routeToHome() {
    if (!this.isServer) {
      if (this.router.url === '/') {
        // IF THE SCROLL POSITION IS BELOW THE FOLD I.E A LITTLE PAST THE HERO SECTION THEN IT CAN SCROLL TO TOP
        // ADDING SCROLL TO THE LOGO SO IT CAN BE USED AS A QUICK WAY TO SCROLL UP
        if (this.scrollPosition > 400) {
          window.scroll(0, 0)
        }
      }
      this.closeMenu()
    }
  }
}
