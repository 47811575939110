<div class="sidebar-container">
  <a [routerLink]="profilePaths.MyProfilePath">
    <u-logo width="126" height="32" textFill="#000"></u-logo>
  </a>

  <div class="flex-1">
    <div class="sidebar-items">
      <a
        class="sidebar-item"
        [routerLink]="profilePaths.MyProfilePath"
        routerLinkActive="active-link"
        (click)="close()"
      >
        <svg-icon key="my-profile" class="item-icon"></svg-icon>
        <span class="item-title">My Profile</span>
        <svg-icon key="arrow-forward" class="item-icon-forward"></svg-icon>
      </a>
      <a
        class="sidebar-item"
        routerLinkActive="active-link"
        [routerLink]="appPaths.MyResumes"
        (click)="close()"
      >
        <svg-icon key="file-text-2" class="item-icon"></svg-icon>
        <span class="item-title">My Resumes</span>
        <svg-icon key="arrow-forward" class="item-icon-forward"></svg-icon>
      </a>
      <a
        class="sidebar-item"
        routerLinkActive="active-link"
        [routerLink]="appPaths.MyJobs"
        (click)="close()"
      >
        <svg-icon key="monitor-alt" class="item-icon"></svg-icon>
        <span class="item-title">My Jobs</span>
        <svg-icon key="arrow-forward" class="item-icon-forward"></svg-icon>
      </a>
      <div class="flex w-full p-2">
        <hr class="w-full my-3" />
      </div>
      <a
        class="sidebar-item"
        routerLinkActive="active-link"
        [routerLink]="getCarrerCourseLink()"
        (click)="close()"
      >
        <svg-icon key="book" class="item-icon"></svg-icon>
        <span class="item-title">Career Course</span>
        <svg-icon key="arrow-forward" class="item-icon-forward"></svg-icon>
      </a>
      <a
        class="sidebar-item"
        uTrack
        [trackMixPanel]="{
          fn: 'track',
          info: {
            eventName: 'SBC - Dashboard menu'
          }
        }"
        routerLinkActive="active-link"
        [routerLink]="getSelfBrandingCourseLink()"
        (click)="close()"
      >
        <svg-icon key="book" class="item-icon"></svg-icon>
        <span class="item-title">Self-branding Course</span>
        <svg-icon key="arrow-forward" class="item-icon-forward"></svg-icon>
      </a>
      <div class="flex w-full p-2">
        <hr class="w-full my-3" />
      </div>
      <a
        class="sidebar-item"
        routerLinkActive="active-link"
        [routerLink]="appPaths.JobPostings"
        (click)="close()"
      >
        <svg-icon key="laptop" class="item-icon"></svg-icon>
        <span class="item-title">Jobs & Interships</span>
        <svg-icon key="arrow-forward" class="item-icon-forward"></svg-icon>
      </a>
    </div>
  </div>

  <div class="sidebar-footer">
    <div class="sidebar-items" @toggleSideFooter *ngIf="showSideFooter">
      <a class="sidebar-item" routerLinkActive="active-link" [routerLink]="profilePaths.ReferralPath">
        <svg-icon key="invite" class="item-icon"></svg-icon>
        <span class="item-title">Invite Friends</span>
        <svg-icon key="arrow-forward" class="item-icon-forward"></svg-icon>
      </a>
      <a class="sidebar-item" routerLinkActive="active-link" [routerLink]="profilePaths.SettingsPath">
        <svg-icon key="settings" class="item-icon"></svg-icon>
        <span class="item-title">Settings</span>
        <svg-icon key="arrow-forward" class="item-icon-forward"></svg-icon>
      </a>
      <!-- <a class="sidebar-item" routerLinkActive="active-link">
        <svg-icon key="bitcoin-circle" class="item-icon"></svg-icon>
        <span class="item-title">My credits</span>
        <svg-icon key="arrow-forward" class="item-icon-forward"></svg-icon>
      </a> -->
      <a
        (click)="logOut()"
        href="javascript:void(0)"
        class="sidebar-item"
        routerLinkActive="active-link"
      >
        <svg-icon key="log-out" class="item-icon"></svg-icon>
        <span class="item-title">Log Out</span>
      </a>
    </div>

    <div class="user-info" *ngIf="currentUser$ | async; let user">
      <div class="user-info-wrapper" (click)="toggle()">
        <div class="user-avatar">
          <ng-container *ngIf="user.profileAvatar; let profileAvatar; else: abbreviationName">
            <img [src]="profileAvatar" alt="Profile Avatar" />
          </ng-container>
          <ng-template #abbreviationName>
            <span *ngIf="user.firstName && user.lastName">
              {{ user.firstName.charAt(0) | uppercase }}{{ user.lastName.charAt(0) | uppercase }}
            </span>
          </ng-template>
        </div>
        <div class="flex flex-1 justify-between items-center space-x-2">
          <span class="user-name"> {{ user.firstName }} {{ user.lastName }} </span>
          <svg-icon key="angle-down" [ngClass]="{ rotate: showSideFooter }" size="lg"></svg-icon>
        </div>
      </div>
    </div>
  </div>
</div>
