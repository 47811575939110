/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ViewportScroller } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ErrorHandler, Injector, NgModule } from '@angular/core'
import { BrowserModule, BrowserTransferStateModule, Title } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { Router } from '@angular/router'
import { SvgIconsModule } from '@ngneat/svg-icon'
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin'
import { NgxsModule } from '@ngxs/store'
import * as Sentry from '@sentry/angular'
import { UserAccountState } from '@upkey-platform/accounts/user-data-access'
import { AppInjector, DirectivesModule } from '@upkey-platform/core/core-frontend'
import { environment } from '@upkey-platform/core/core-shared'
import { commonIcons, socialIcons } from '@upkey-platform/icons'
import { MyResumesState } from '@upkey-platform/resumes/resumes-data-access'
import { ToastNotificationComponent, UiModule } from '@upkey-platform/ui'
import { PixelModule } from 'ngx-pixel'
import { ShareModule, SharerMethod } from 'ngx-sharebuttons'
import { ToastrModule } from 'ngx-toastr'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { GlobalErrorHandler } from './core/error-handler/global-error-handler'
import { handleScrollOnNavigation } from './core/utils/scroll-restoration'
import { BrowserStateInterceptor } from './interceptors/browser-state/browser-state.interceptor'
import { AppLayoutModule } from './layout/app-layout/app-layout.module'
import { PublicLayoutModule } from './layout/public-layout/public-layout.module'
import { SharedModule } from './shared/shared.module'

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    NgxsModule.forRoot([UserAccountState, MyResumesState], { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
    PublicLayoutModule,
    AppLayoutModule,
    SvgIconsModule.forRoot({
      icons: [...commonIcons, ...socialIcons]
    }),
    UiModule,
    HttpClientModule,
    PixelModule.forRoot({ enabled: true, pixelId: environment.facebookPixelId }),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 6000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      toastComponent: ToastNotificationComponent
    }),
    SharedModule,
    DirectivesModule,
    ShareModule.withConfig({
      sharerMethod: SharerMethod.Window
    })
  ],
  providers: [
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector, private router: Router, private viewportScroller: ViewportScroller) {
    AppInjector.setInjector(injector)

    // Disable automatic scroll restoration to avoid race conditions
    this.viewportScroller.setHistoryScrollRestoration('manual')
    handleScrollOnNavigation(this.router, this.viewportScroller)
  }
}
