import { environment } from '@upkey-platform/core/core-shared'

export class SEOHelper {
  static metaTitle(title: string) {
    return `${title} | Upkey`
  }

  static metaImage(imagePath: string, url = environment.websiteUrl) {
    return url + imagePath
  }
}
