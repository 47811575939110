<div *uIfPlatform="'browser'" class="flex flex-col h-screen" [class.overflow-hidden]="showMenu">
  <header class="w-full lg:hidden z-10 bg-white">
    <div class="flex justify-between p-3">
      <div class="flex-1 flex justify-between items-center">
        <a [routerLink]="profilePaths.MyProfilePath">
          <u-logo width="126" height="32" textFill="#000"></u-logo>
        </a>
      </div>

      <u-button
        size="medium-base"
        variant=""
        [icon]="showMenu ? 'close' : 'menu'"
        extraBtnClass="text-black cursor-pointer text-xl leading-none border border-solid border-transparent bg-transparent block lg:hidden outline-none focus:outline-none"
        fontSize="24px"
        iconWidth="24px"
        [isIconOnly]="true"
        (click)="toggleNavbar()"
      >
      </u-button>
    </div>
  </header>
  <div class="flex flex-wrap lg:flex-row h-full" *ngIf="currentUser$ | async">
    <div class="hidden lg:flex flex-wrap">
      <ng-container *ngTemplateOutlet="sidebar"></ng-container>
    </div>

    <div
      [ngClass]="{ hidden: !showMenu, flex: showMenu }"
      class="absolute flex lg:hidden flex-wrap top-0 bottom-0 inset-x-0 w-2/3 h-full lg:static lg:inset-0 z-20"
    >
      <ng-container *ngTemplateOutlet="sidebar"></ng-container>
    </div>

    <div class="flex-1 overflow-auto main-section bg-gray-150">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<ng-template #sidebar>
  <u-sidebar (closeSideBar)="toggleNavbar()"></u-sidebar>
</ng-template>
